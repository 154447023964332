import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";

const useFetchPricing = () => {
  const navigate = useNavigate();
  const data = useData();
  const [configuration, setConfiguration] = useState(null);
  const [basePrice, setBasePrice] = useState("");
  const [cuisineMultipliers, setCuisineMultipliers] = useState([]);
  const [mealTypeMultipliers, setMealTypeMultipliers] = useState([]);
  const [mealTimeMultipliers, setMealTimeMultipliers] = useState([]);
  const [periodMultipliers, setperiodMultipliers] = useState([]);

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const {
          data: { kitchenRegistration },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { configuration, pricing } = kitchenRegistration;

        if (!configuration) {
          return navigate(`/kitchen-registration/configuration`, {
            replace: true,
          });
        }

        setConfiguration(configuration);

        let cuisineMultipliers = configuration.cuisines.map((cuisine) => ({
          state: cuisine,
          label: data.cuisineLabels[cuisine],
          value: data.cuisineMultipliers[cuisine],
        }));

        let mealTypeMultipliers = configuration.mealTypes.map((mealType) => ({
          state: mealType,
          label: data.mealTypeLabels[mealType],
          value: data.mealTypeMultipliers[mealType],
        }));

        let mealTimeMultipliers = configuration.mealTimes.map((mealTime) => ({
          state: mealTime,
          label: data.mealTimeLabels[mealTime],
          value: data.mealTimeMultipliers[mealTime],
        }));

        let periodMultipliers = configuration.periods.map((period) => ({
          state: period,
          label: data.periodLabels[period],
          value: data.periodMultipliers[period],
        }));

        if (pricing) {
          setBasePrice(pricing.basePrice);

          const createMultiplierMap = (category, list) => {
            const multiplierMap = {};
            list.forEach((item) => {
              multiplierMap[item[category]] = item.multiplier;
            });
            return multiplierMap;
          };

          const cuisineMultiplierMap = createMultiplierMap(
            "cuisine",
            pricing.cuisineMultipliers,
          );
          const mealTypeMultiplierMap = createMultiplierMap(
            "mealType",
            pricing.mealTypeMultipliers,
          );
          const mealTimeMultiplierMap = createMultiplierMap(
            "mealTime",
            pricing.mealTimeMultipliers,
          );
          const periodMultiplierMap = createMultiplierMap(
            "period",
            pricing.periodMultipliers,
          );

          cuisineMultipliers = cuisineMultipliers.map((item) => {
            if (cuisineMultiplierMap[item.state]) {
              return {
                ...item,
                value: cuisineMultiplierMap[item.state],
              };
            }
            return item;
          });

          mealTypeMultipliers = mealTypeMultipliers.map((item) => {
            if (mealTypeMultiplierMap[item.state]) {
              return {
                ...item,
                value: mealTypeMultiplierMap[item.state],
              };
            }
            return item;
          });

          mealTimeMultipliers = mealTimeMultipliers.map((item) => {
            if (mealTimeMultiplierMap[item.state]) {
              return {
                ...item,
                value: mealTimeMultiplierMap[item.state],
              };
            }
            return item;
          });

          periodMultipliers = periodMultipliers.map((item) => {
            if (periodMultiplierMap[item.state]) {
              return {
                ...item,
                value: periodMultiplierMap[item.state],
              };
            }
            return item;
          });
        }

        setCuisineMultipliers(cuisineMultipliers);
        setMealTypeMultipliers(mealTypeMultipliers);
        setMealTimeMultipliers(mealTimeMultipliers);
        setperiodMultipliers(periodMultipliers);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPricing();
  }, [data, navigate]);

  return {
    configuration,
    basePrice,
    setBasePrice,
    cuisineMultipliers,
    setCuisineMultipliers,
    mealTypeMultipliers,
    setMealTypeMultipliers,
    mealTimeMultipliers,
    setMealTimeMultipliers,
    periodMultipliers,
    setperiodMultipliers,
  };
};

export default useFetchPricing;
