import { Card, CardContent } from "@mui/material";
import { useParams } from "react-router-dom";

import ColonText from "@/components/ColonText";
import { useData } from "@/contexts/DataContext";
import { verticalComponentMargin } from "@/utils/styleConstants";

const TaskDetails = () => {
  const { date, cuisine, mealType, mealTime } = useParams();
  const { cuisineLabels, mealTypeLabels, mealTimeLabels } = useData();

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <ColonText first="Date" second={new Date(date).toDateString()} />
        <ColonText first="Cuisine" second={cuisineLabels[cuisine]} />
        <ColonText first="Meal Type" second={mealTypeLabels[mealType]} />
        <ColonText first="Meal Time" second={mealTimeLabels[mealTime]} />
      </CardContent>
    </Card>
  );
};

export default TaskDetails;
