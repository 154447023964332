import MenuScheduleCategoriesPage from "./pages/MenuScheduleCategoriesPage";
import MenuScheduleDaysPage from "./pages/MenuScheduleDaysPage";
import MenuSchedulePage from "./pages/MenuSchedulePage";
import PendingMenuScheduleCategoriesPage from "./pages/PendingMenuScheduleCategoriesPage";
import PendingMenuScheduleDaysPage from "./pages/PendingMenuScheduleDaysPage";

const menuScheduleRoutes = [
  {
    path: "menu-schedules/pending/categories",
    component: <PendingMenuScheduleCategoriesPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menu-schedules/pending/days/:cuisine/:mealType/:mealTime",
    component: <PendingMenuScheduleDaysPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menu-schedules/categories",
    component: <MenuScheduleCategoriesPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menu-schedules/days/:cuisine/:mealType/:mealTime",
    component: <MenuScheduleDaysPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menu-schedules/:cuisine/:mealType/:mealTime/:day",
    component: <MenuSchedulePage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
];

export default menuScheduleRoutes;
