import { Typography } from "@mui/material";

import GreyCover from "@/components/GreyCover";

import DialerIcon from "./DialerIcon";
import NavigationIcon from "./NavigationIcon";

const DeliveryAddress = ({ address }) => {
  const { location, contactNumber } = address;
  const [longitude, latitude] = location.coordinates;

  return (
    <GreyCover>
      <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
        Address
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography>{address.contactName}</Typography>
          <Typography>{address.contactNumber}</Typography>
        </div>
        <div>
          <NavigationIcon latitude={latitude} longitude={longitude} />
          <DialerIcon mobile={contactNumber} />
        </div>
      </div>
      <Typography variant="body2" color="text.secondary">
        {address.building}
        {address.area && `, ${address.area}`}
        {address.landmark && `, ${address.landmark}`}
      </Typography>
      <Typography variant="caption" color="text.secondary">
        {address.formattedAddress && `${address.formattedAddress}`}
      </Typography>
    </GreyCover>
  );
};

export default DeliveryAddress;
