import OrDivider from "@/components/OrDivider";

import CreateMenu from "./CreateMenu";
import SelectMenu from "./SelectMenu";

const CreateOrSelectMenu = ({ menus }) => {
  return (
    <>
      <CreateMenu />
      <OrDivider />
      <SelectMenu menus={menus} />
    </>
  );
};

export default CreateOrSelectMenu;
