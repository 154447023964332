import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import OrderCard from "./components/OrderCard";

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const {
          data: { orders },
        } = await api.orders.getAll();
        setOrders(orders);
      } catch (error) {
        handleError(error);
      }
    };
    fetchOrders();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Orders</Title>
        {orders.map((order) => {
          return <OrderCard key={order._id} order={order} />;
        })}
        {orders.length === 0 && (
          <NoListFoundText>No orders found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default OrdersPage;
