import { TextField } from "@mui/material";

import getPropertyValue from "./getPropertyValue";

const Input = ({
  label,
  state,
  formik,
  inputType = "text",
  disabled = false,
  multiline = false,
  rows = 1,
  restOfProps,
}) => {
  const { values, touched, errors, handleChange, handleBlur } = formik;

  const value = getPropertyValue(values, state) || "";
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = isTouched && error;

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      name={state}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={Boolean(showError)}
      helperText={showError ? error : " "}
      type={inputType}
      disabled={disabled}
      multiline={multiline}
      rows={rows}
      style={{ marginBottom: "8px" }}
      {...restOfProps}
    />
  );
};

export default Input;
