import { Card, CardContent, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import api from "@/api";
import handleError from "@/utils/handleError";

const SelectMenu = ({ menus }) => {
  const navigate = useNavigate();
  const { day } = useParams();

  const menuClickHandler = async (menuId) => {
    try {
      await api.menuSchedules.schedule({
        menuId,
        day,
      });
      toast.success("Menu scheduled successfully");
      navigate(-1);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Typography textAlign={"center"} style={{ marginBottom: "15px" }}>
        Choose from saved menus
      </Typography>
      {menus.map((menu) => {
        return (
          <Card
            key={menu._id}
            onClick={() => menuClickHandler(menu._id)}
            style={{ cursor: "pointer", marginBottom: "15px" }}
          >
            <CardContent>
              <Typography variant="body1" display={"inline"}>
                {menu.title + " : "}
              </Typography>
              <Typography
                variant="body2"
                color={"text.secondary"}
                display={"inline"}
              >
                {menu.description}
              </Typography>
            </CardContent>
          </Card>
        );
      })}
    </>
  );
};

export default SelectMenu;
