import SettlementsPage from "./pages/SettlementsPage";

const settlementRoute = {
  path: "settlements",
  component: <SettlementsPage />,
  allowedKitchenRoles: ["owner", "admin"],
  isProtected: true,
};

export default settlementRoute;
