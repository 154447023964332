import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import ExistingAddress from "./components/ExistingAddress";
import NewAddress from "./components/NewAddress";

const AddressPage = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState(null);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const {
          data: {
            kitchenRegistration: { address },
          },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();
        setAddress(address);
      } catch (error) {
        handleError(error);
      }
    };
    fetchAddress();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title
          button={
            address && (
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForward />}
                onClick={() => navigate("/kitchen-registration/address/edit")}
              >
                Edit Address
              </Button>
            )
          }
          margin={"20px 0px 30px 0px"}
        >
          Address
        </Title>
        {address ? <ExistingAddress address={address} /> : <NewAddress />}
      </Container>
    </>
  );
};

export default AddressPage;
