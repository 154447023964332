import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./common/CardHeader";

const BankDetailsDisplay = ({ isEditable, bankDetails }) => {
  let { accountNumber, ifscCode } = bankDetails;

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title={"Bank Details"}
          editPath={isEditable && "/kitchen-registration/bank-details"}
        />
        <LabelValueText label="Account Number" value={accountNumber} />
        <LabelValueText label="IFSC Code" value={ifscCode} />
      </CardContent>
    </Card>
  );
};

export default BankDetailsDisplay;
