import { useState } from "react";
import { Container } from "@mui/material";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import { useData } from "@/contexts/DataContext";
import { useKitchen } from "@/contexts/KitchenContext";
import { getCurrentDay12AM } from "@/utils/dateUtils";

import DeliveryTask from "./components/DeliveryTask";
import LocationAccess from "./components/LocationAccess";
import getMealTimesInDeliveryWindow from "./utils/getMealTimesInDeliveryWindow";

const DeliveryTasksPage = () => {
  const { kitchen } = useKitchen();
  const { mealTimeDeliveryWindow } = useData();
  const date = getCurrentDay12AM();
  const [currentLocation, setCurrentLocation] = useState({});

  const isCurrentLocationAvailable = Boolean(
    currentLocation.latitude && currentLocation.longitude,
  );
  const mealTimesInDeliveryWindow = getMealTimesInDeliveryWindow({
    mealTimes: kitchen.configuration.mealTimes,
    deliveryWindow: mealTimeDeliveryWindow,
  });

  return (
    <>
      <Header />
      <Container>
        <Title subtitle={new Date(date).toDateString()}>Delivery Tasks</Title>
        {!isCurrentLocationAvailable && (
          <LocationAccess onCurrentLocationChange={setCurrentLocation} />
        )}
        {isCurrentLocationAvailable &&
          mealTimesInDeliveryWindow.map((mealTime) => (
            <DeliveryTask
              key={mealTime}
              date={date}
              mealTime={mealTime}
              currentLocation={currentLocation}
            />
          ))}
        {mealTimesInDeliveryWindow.length === 0 && (
          <NoListFoundText>No delivery tasks found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default DeliveryTasksPage;
