import { deliveryStatusToColorMapping } from "@/utils/mappings";

import DeliveryProgressBar from "./DeliveryProgressBar";

const DeliveryProgress = ({ order }) => {
  const {
    completedDates,
    deliverableDates,
    skippedDates,
    skipLimit,
    missedDates,
    missLimit,
  } = order;

  const deliveryProgressList = [
    {
      dates: completedDates,
      total: deliverableDates.length,
      label: "Completed",
      color: deliveryStatusToColorMapping.completed,
    },
    {
      dates: skippedDates,
      total: skipLimit,
      label: "Skipped",
      color: deliveryStatusToColorMapping.skipped,
    },
    {
      dates: missedDates,
      total: missLimit,
      label: "Missed",
      color: deliveryStatusToColorMapping.missed,
    },
  ];

  if (order.finalStatus === "cancelled") {
    deliveryProgressList.push({
      dates: order.remainingDates,
      total: deliverableDates.length,
      label: "Cancelled",
      color: deliveryStatusToColorMapping.cancelled,
    });
  }

  if (order.finalStatus === "expired") {
    deliveryProgressList.push({
      dates: order.remainingDates,
      total: deliverableDates.length,
      label: "Expired",
      color: deliveryStatusToColorMapping.expired,
    });
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {deliveryProgressList.map((deliveryProgress, index) => (
        <DeliveryProgressBar
          key={index}
          dates={deliveryProgress.dates}
          total={deliveryProgress.total}
          label={deliveryProgress.label}
          color={deliveryProgress.color}
        />
      ))}
    </div>
  );
};

export default DeliveryProgress;
