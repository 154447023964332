import { useCallback, useEffect, useState } from "react";
import { Card, CardContent, Chip, Typography } from "@mui/material";

import NoListFoundText from "@/components/NoListFoundText";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import { resetDateTo12AM } from "@/utils/dateUtils";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

import DeliveryContent from "./DeliveryContent";

const DeliveryTask = ({ date, mealTime, currentLocation }) => {
  const { mealTimeLabels } = useData();
  const [nearestDelivery, setNearestDelivery] = useState({});
  const [remainingDeliveriesCount, setRemainingDeliveriesCount] = useState(0);

  const fetchNearestDelivery = useCallback(async () => {
    try {
      const {
        data: { delivery, remainingDeliveriesCount },
      } = await api.deliveries.getNearest({
        date: resetDateTo12AM(date),
        mealTime,
        latitude: currentLocation.latitude,
        longitude: currentLocation.longitude,
      });

      setNearestDelivery(delivery);
      setRemainingDeliveriesCount(remainingDeliveriesCount);
    } catch (err) {
      handleError(err);
    }
  }, [
    date,
    mealTime,
    setNearestDelivery,
    currentLocation.latitude,
    currentLocation.longitude,
    setRemainingDeliveriesCount,
  ]);

  useEffect(() => {
    fetchNearestDelivery();
  }, [fetchNearestDelivery]);

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Typography variant="h6">{mealTimeLabels[mealTime]}</Typography>
          {remainingDeliveriesCount > 0 && (
            <Chip
              size="small"
              color="success"
              label={`Remaining deliveries : ${remainingDeliveriesCount}`}
            />
          )}
        </div>
        {nearestDelivery._id && (
          <DeliveryContent
            delivery={nearestDelivery}
            fetchNearestDelivery={fetchNearestDelivery}
          />
        )}
        {!nearestDelivery._id && (
          <NoListFoundText>No delivery found</NoListFoundText>
        )}
      </CardContent>
    </Card>
  );
};

export default DeliveryTask;
