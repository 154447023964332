import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";

const HomePage = () => {
  const navigate = useNavigate();
  const { kitchen, kitchenRole, fetchMyKitchenDetails } = useKitchen();

  useEffect(() => {
    const checkAndRedirect = async () => {
      try {
        await fetchMyKitchenDetails();

        if (kitchen._id) {
          if (kitchen.status === "pending") {
            return navigate("/kitchen/activate", { replace: true });
          }
          if (kitchenRole === "rider") {
            return navigate(`/delivery-tasks`, { replace: true });
          }
          return navigate(`/dispatch-tasks`, { replace: true });
        }

        const kitchenUserInvitesRes =
          await api.kitchenUserInvites.getAllActiveReceivedInvites();
        const { kitchenUserInvites } = kitchenUserInvitesRes.data;
        if (kitchenUserInvites.length > 0) {
          return navigate(`/kitchen-users/invites/received`, {
            replace: true,
          });
        }

        return navigate("/kitchen-registration", { replace: true });
      } catch (error) {
        handleError(error);
      }
    };
    checkAndRedirect();
  }, [kitchen, navigate, kitchenRole, fetchMyKitchenDetails]);

  return null;
};

export default HomePage;
