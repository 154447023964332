import { Chip, Typography } from "@mui/material";

const statusMapper = {
  deliverable: "Upcoming",
  nonDeliverable: "Non-Deliverable",
  skipped: "Skipped",
  missed: "Missed",
  cancelled: "Cancelled",
  expired: "Expired",
  dispatched: "Dispatched",
  completed: "Completed",
  invalid: "Invalid",
};

const colorMapper = {
  deliverable: "success",
  nonDeliverable: "default",
  skipped: "info",
  missed: "warning",
  cancelled: "error",
  expired: "default",
  dispatched: "success",
  completed: "success",
};

const DeliveryHeader = ({ date, deliveryStatus }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: "15px 0px",
      }}
    >
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6">Delivery</Typography>
          <Chip
            style={{ marginLeft: "10px" }}
            size="small"
            label={statusMapper[deliveryStatus]}
            color={colorMapper[deliveryStatus]}
          />
        </div>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          style={{ marginTop: "5px" }}
        >
          {new Date(date).toDateString()}
        </Typography>
      </div>
    </div>
  );
};

export default DeliveryHeader;
