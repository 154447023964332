import CompleteDeliveryButton from "./CompleteDeliveryButton";
import MissDeliveryButton from "./MissDeliveryButton";

const DeliveryActions = ({ deliveryId, fetchNearestDelivery }) => {
  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          margin: "10px 0px",
        }}
      >
        <CompleteDeliveryButton
          deliveryId={deliveryId}
          fetchNearestDelivery={fetchNearestDelivery}
        />
        <MissDeliveryButton
          deliveryId={deliveryId}
          fetchNearestDelivery={fetchNearestDelivery}
        />
      </div>
    </>
  );
};

export default DeliveryActions;
