import { ArrowForward } from "@mui/icons-material";
import { Button, Card, CardContent, Typography } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

const LocationDisplay = ({
  formattedAddress,
  confirmLocationHandler,
  viewLocationTitle,
  btnName,
}) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        {viewLocationTitle && (
          <Typography variant="subtitle1" gutterBottom fontWeight={"bold"}>
            Location
          </Typography>
        )}
        <Typography>{formattedAddress}</Typography>
        {confirmLocationHandler && (
          <Button
            size="small"
            fullWidth
            variant="contained"
            endIcon={<ArrowForward />}
            style={{ marginTop: "10px" }}
            color="success"
            onClick={confirmLocationHandler}
          >
            {btnName}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default LocationDisplay;
