import ColonText from "@/components/ColonText";
import MenuDisplay from "@/components/MenuDisplay";
import { useData } from "@/contexts/DataContext";

import DeliveryActions from "./DeliveryActions";
import DeliveryAddress from "./DeliveryAddress";

const DeliveryContent = ({ delivery, fetchNearestDelivery }) => {
  const { cuisineLabels, mealTimeLabels, mealTypeLabels } = useData();
  return (
    <div>
      <ColonText first={"Order ID"} second={delivery.order} />
      <ColonText
        first={"Meal Plan"}
        second={
          cuisineLabels[delivery.cuisine] +
          " " +
          mealTypeLabels[delivery.mealType] +
          " " +
          mealTimeLabels[delivery.mealTime]
        }
      />
      <MenuDisplay
        title={delivery.menu.title}
        description={delivery.menu.description}
        showMenuLabel
      />
      <DeliveryAddress address={delivery.address} />
      <DeliveryActions
        deliveryId={delivery._id}
        fetchNearestDelivery={fetchNearestDelivery}
      />
    </div>
  );
};

export default DeliveryContent;
