import { Alert, Card, CardContent } from "@mui/material";

import ColonText from "@/components/ColonText";
import Title from "@/components/Title";
import { formatTimestamp } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const SettlementSummary = () => {
  const { order } = useOrderPageContext();

  if (!order.settlement) return null;

  const { settlement } = order;
  const { settlementAmount, status } = settlement;

  return (
    <>
      <Title alignLeft>Settlement</Title>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <ColonText first={"Amount"} second={"₹" + settlementAmount} />
          <ColonText first={"Status"} second={status} />
          {settlement.status === "completed" && (
            <ColonText
              first={"Settlement completed at"}
              second={formatTimestamp(
                settlement.timeline[settlement.timeline.length - 1].timestamp,
              )}
            />
          )}
          {settlement.status === "pending" && (
            <Alert severity="warning">
              We are processing the settlement. Thank you for your patience.
            </Alert>
          )}
          {settlement.status === "failed" && (
            <Alert severity="error">
              Settlement failed. Our team is looking into it and will get back
              to you soon.
            </Alert>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default SettlementSummary;
