import { Card, CardContent, Rating, Typography } from "@mui/material";

const ExistingRating = ({ rating, review }) => {
  return (
    <Card
      style={{
        margin: "15px 0px",
      }}
    >
      <CardContent>
        <Typography
          variant="subtitle1"
          marginBottom={"10px"}
          fontWeight={"bold"}
        >
          Your Rating
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Typography variant="body1" display={"inline"}>
            Rating :{" "}
          </Typography>
          <Rating value={rating} />
        </div>
        <div>
          <Typography variant="body1" display={"inline"}>
            Review :{" "}
          </Typography>
          <Typography
            variant="body2"
            display={"inline"}
            color={"text.secondary"}
          >
            {review}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

export default ExistingRating;
