import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import api from "@/api";
import handleError from "@/utils/handleError";

const OrderPageContext = createContext();

export const useOrderPageContext = () => useContext(OrderPageContext);

export const OrderPageContextProvider = ({ children }) => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);

  const fetchOrder = useCallback(async () => {
    try {
      const {
        data: { order },
      } = await api.orders.get({ orderId });
      setOrder(order);
    } catch (error) {
      handleError(error);
    }
  }, [orderId]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  const contextValue = {
    order,
    fetchOrder,
    setOrder,
  };

  const isDataReady = Boolean(order);

  return (
    <OrderPageContext.Provider value={contextValue}>
      {isDataReady ? children : null}
    </OrderPageContext.Provider>
  );
};
