import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/menus/";

const create = async ({ cuisine, mealType, mealTime, title, description }) =>
  (
    await axios.post(base, {
      cuisine,
      mealType,
      mealTime,
      title,
      description,
    })
  ).data;

const get = async ({ menuId }) => (await axios.get(base + menuId)).data;

const update = async ({
  menuId,
  cuisine,
  mealType,
  mealTime,
  title,
  description,
}) =>
  (
    await axios.patch(base + menuId, {
      cuisine,
      mealType,
      mealTime,
      title,
      description,
    })
  ).data;

const remove = async ({ menuId }) => (await axios.delete(base + menuId)).data;

const menus = {
  create,
  getAll: getAll(base),
  get,
  update,
  delete: remove,
};

export default menus;
