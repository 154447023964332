import { Container } from "@mui/material";

import Header from "@/components/Header";
import Title from "@/components/Title";

import ActionButtons from "./components/ActionButtons";
import DeliveryList from "./components/DeliveryList";
import DeliverySummary from "./components/DeliverySummary";
import DeliveryWindowAlert from "./components/DeliveryWindowAlert";
import GoToDeliveryTasksPageButton from "./components/GoToDeliveryTasksPageButton";
import MenuSchedule from "./components/MenuSchedule";
import TaskDetails from "./components/TaskDetails";
import { DispatchTaskPageContextProvider } from "./contexts/DispatchTaskPageContext";

const DispatchTaskPage = () => {
  return (
    <DispatchTaskPageContextProvider>
      <Header />
      <Container>
        <Title>Dispatch Task</Title>
        <TaskDetails />
        <MenuSchedule />
        <Title alignLeft>Deliveries</Title>
        <DeliveryWindowAlert />
        <ActionButtons />
        <DeliverySummary />
        <GoToDeliveryTasksPageButton />
        <DeliveryList />
      </Container>
    </DispatchTaskPageContextProvider>
  );
};

export default DispatchTaskPage;
