import KitchenUserInvitesPage from "./pages/KitchenUserInvitesPage";
import KitchenUserInvitesReceivedPage from "./pages/KitchenUserInvitesReceivedPage";
import KitchenUsersPage from "./pages/KitchenUsersPage";
import SendInvitePage from "./pages/SendInvitePage";

const kitchenUserRoutes = [
  {
    path: "kitchen-users",
    component: <KitchenUsersPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "kitchen-users/invites",
    component: <KitchenUserInvitesPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "kitchen-users/invites/send",
    component: <SendInvitePage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "kitchen-users/invites/received",
    component: <KitchenUserInvitesReceivedPage />,
    isProtected: true,
  },
];

export default kitchenUserRoutes;
