import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import OtpInput from "@/components/FormComponents/OtpInput";

const OtpForm = ({ submitHandler, description }) => {
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .required("OTP is required")
        .matches(/^\d+$/, "OTP must contain only digits")
        .length(6, "OTP must be exactly 6 digits"),
    }),
    onSubmit: ({ otp }) => submitHandler(otp),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography
        variant="body2"
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        {description
          ? description
          : "Please enter the 6 digit code sent to your mobile number. Valid for 10 minutes."}
      </Typography>
      <OtpInput formik={formik} label={"Enter OTP"} state={"otp"} />
      <Button
        size="small"
        type="submit"
        fullWidth
        variant="contained"
        color={formik.values.otp.length === 6 ? "success" : "primary"}
      >
        Verify OTP
      </Button>
    </form>
  );
};

export default OtpForm;
