import { useCallback, useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import FormComponents from "@/components/FormComponents";
import Mobile from "@/components/FormComponents/Mobile";
import Header from "@/components/Header";
import { OtpDrawer } from "@/components/OtpVerification";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";
import { objectToFormData } from "@/utils/objectUtils";

import ImageUpload from "./components/ImageUpload";
import UpdateMobileDrawer from "./components/UpdateMobileDrawer";

const BasicDetailsPage = () => {
  const navigate = useNavigate();
  const [kitchenRegistration, setKitchenRegistration] = useState({});
  const [kitchenCoverImageFile, setKitchenCoverImageFile] = useState(null);
  const [kitchenCoverImageSrc, setKitchenCoverImageSrc] = useState(null);
  const [isOtpVerificationOpen, setIsOtpVerificationOpen] = useState(false);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isUpdateMobileDrawerOpen, setIsUpdateMobileDrawerOpen] =
    useState(false);
  const isNewKitchenRegistration = kitchenRegistration._id ? false : true;

  const submitHandler = async (values) => {
    try {
      if (isNewKitchenRegistration) {
        if (!kitchenCoverImageFile) {
          return toast.error("Kitchen cover image is required");
        }
        const formData = objectToFormData({
          ...values,
          kitchenCoverImage: kitchenCoverImageFile,
        });
        const {
          data: { kitchenRegistration },
        } =
          await api.kitchenRegistrations.initiateKitchenRegistration(formData);
        setKitchenRegistration(kitchenRegistration);
        setIsOtpVerificationOpen(true);
      } else {
        let formData;
        if (kitchenCoverImageFile) {
          formData = objectToFormData({
            ...values,
            kitchenCoverImage: kitchenCoverImageFile,
          });
        } else {
          formData = objectToFormData(values);
        }
        await api.kitchenRegistrations.updateBasicDetails(formData);
        if (isMobileVerified) {
          navigate(`/kitchen-registration/address`);
        } else {
          setIsOtpVerificationOpen(true);
        }
      }
    } catch (err) {
      handleError(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      kitchenName: "",
      kitchenTagline: "",
      ownerName: "",
      ownerMobile: "",
    },
    validationSchema: Yup.object({
      kitchenName: Yup.string().required("Kitchen name is required"),
      kitchenTagline: Yup.string(),
      ownerName: Yup.string().required("Owner name is required"),
      ownerMobile: Yup.string().required("Owner mobile is required"),
    }),
    onSubmit: submitHandler,
  });

  const otpSubmitHandler = async (otp) => {
    try {
      await api.kitchenRegistrations.verifyMobile({ otp });
      setIsOtpVerificationOpen(false);
      navigate(`/kitchen-registration/address`);
    } catch (error) {
      handleError(error);
    }
  };

  const fetchBasicDetails = useCallback(async () => {
    try {
      const {
        data: { kitchenRegistration },
      } = await api.kitchenRegistrations.getMyKitchenRegistration();
      setKitchenRegistration(kitchenRegistration);
      if (kitchenRegistration._id) {
        formik.setValues({
          ownerName: kitchenRegistration.ownerDetails.name,
          ownerMobile: kitchenRegistration.ownerDetails.mobileDetails.mobile,
          kitchenName: kitchenRegistration.name,
          kitchenTagline: kitchenRegistration.tagline,
        });
        setKitchenCoverImageSrc(kitchenRegistration.coverImage.s3Location);
        setIsMobileVerified(
          kitchenRegistration.ownerDetails.mobileDetails.isVerified,
        );
      }
    } catch (error) {
      handleError(error);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchBasicDetails();
  }, [fetchBasicDetails]);

  return (
    <>
      <Header />
      <Title>Basic details</Title>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            data={[
              {
                type: "input",
                label: "Kitchen name",
                state: "kitchenName",
                inputType: "text",
              },
              {
                type: "input",
                label: "Kitchen tagline (optional)",
                state: "kitchenTagline",
                multiline: true,
                rows: 3,
              },
            ]}
            formik={formik}
          />
          <div style={{ marginBottom: "25px" }}>
            <ImageUpload
              label="Kitchen Cover Image"
              onImageUpload={(image) => setKitchenCoverImageFile(image)}
              imageSrc={kitchenCoverImageSrc}
            />
          </div>
          <FormComponents
            data={[
              {
                type: "input",
                label: "Owner name",
                state: "ownerName",
                inputType: "text",
              },
            ]}
            formik={formik}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Mobile
              formik={formik}
              label={
                isMobileVerified ? "Owner mobile (verified)" : "Owner mobile"
              }
              state={"ownerMobile"}
              disabled={isMobileVerified}
            />
            {isMobileVerified && (
              <Button
                size="small"
                style={{ marginLeft: "10px" }}
                onClick={() => setIsUpdateMobileDrawerOpen(true)}
              >
                Change
              </Button>
            )}
          </div>
          <BottomButton
            arrowDirection={isMobileVerified ? "forward" : "upward"}
          >
            {isMobileVerified ? "Address" : "Verify mobile to proceed"}
          </BottomButton>
        </form>
      </Container>
      <OtpDrawer
        isOpen={isOtpVerificationOpen}
        setIsOpen={setIsOtpVerificationOpen}
        submitHandler={otpSubmitHandler}
        description="Please enter the 6 digit code sent to your mobile. Valid for 10 minutes."
      />
      {isUpdateMobileDrawerOpen && (
        <UpdateMobileDrawer
          open={isUpdateMobileDrawerOpen}
          setOpen={setIsUpdateMobileDrawerOpen}
          fetchBasicDetails={fetchBasicDetails}
          existingMobile={formik.values.ownerMobile}
        />
      )}
    </>
  );
};

export default BasicDetailsPage;
