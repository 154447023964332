import axios from "axios";

let base = "/kitchenUsers/";

const getAll = async () => (await axios.get(base)).data;

const remove = async ({ kitchenUserId }) =>
  (await axios.delete(base + kitchenUserId)).data;

const kitchenUsers = {
  getAll,
  delete: remove,
};

export default kitchenUsers;
