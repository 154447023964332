import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import MenuDisplay from "@/components/MenuDisplay";
import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import { getDayFromDate } from "@/utils/dateUtils";
import handleError from "@/utils/handleError";

const MenuSchedule = () => {
  const { date, cuisine, mealType, mealTime } = useParams();
  const { kitchen } = useKitchen();

  const [menuSchedule, setMenuSchedule] = useState({ menu: {} });

  useEffect(() => {
    const fetchMenuSchedule = async () => {
      try {
        const {
          data: { menuSchedule },
        } = await api.menuSchedules.get({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
          day: getDayFromDate(date),
        });
        setMenuSchedule(menuSchedule);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMenuSchedule();
  }, [kitchen, cuisine, mealType, mealTime, date]);

  return (
    <MenuDisplay
      title={menuSchedule.menu.title}
      description={menuSchedule.menu.description}
      cardView
      showMenuLabel
    />
  );
};

export default MenuSchedule;
