import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";

import BottomDrawer from "@/components/BottomDrawer";

import "react-circular-progressbar/dist/styles.css";

import { useNavigate, useParams } from "react-router-dom";

import NoListFoundText from "@/components/NoListFoundText";
import ProgressBar from "@/components/ProgressBar";

const DeliveryProgressBar = ({ dates, total, label, color }) => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const actual = dates.length;
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  return (
    <>
      <div
        style={{
          cursor: "pointer",
        }}
        onClick={openDrawer}
      >
        <ProgressBar
          actual={actual}
          total={total}
          label={label}
          color={color}
        />
      </div>
      <BottomDrawer
        isOpen={open}
        setIsOpen={setOpen}
        title={`${label} Deliveries`}
      >
        <Container>
          {dates.map((date) => (
            <Card style={{ margin: "10px 0px" }} key={date}>
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>{new Date(date).toDateString()}</Typography>
                  <Button
                    onClick={() =>
                      navigate(`/orders/${orderId}/delivery/${date}`)
                    }
                    style={{ color }}
                  >
                    View
                  </Button>
                </div>
              </CardContent>
            </Card>
          ))}
          {dates.length === 0 && (
            <NoListFoundText>No {label} deliveries found</NoListFoundText>
          )}
        </Container>
      </BottomDrawer>
    </>
  );
};

export default DeliveryProgressBar;
