import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/orders/";

const getAllOrders = getAll(base);

const get = async ({ orderId }) =>
  (await axios.get(base + orderId + "?role=kitchen")).data;

const processExpiredOrder = async ({ orderId }) =>
  (await axios.post(base + orderId + "/processExpiredOrder?role=kitchen")).data;

const orders = {
  getAll: getAllOrders,
  get,
  processExpiredOrder,
};

export default orders;
