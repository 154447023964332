import { TextField } from "@mui/material";

const ReadOnlyFormComponents = ({ data }) => {
  return (
    <>
      {data.map((item, index) => {
        if (!item.value) return null;
        return (
          <TextField
            key={index}
            size="small"
            fullWidth
            label={item.label}
            value={item.value}
            InputProps={{
              readOnly: true,
            }}
            style={{ display: "block", marginBottom: "20px" }}
          />
        );
      })}
    </>
  );
};

export default ReadOnlyFormComponents;
