import React from "react";
import { Map, Marker } from "@vis.gl/react-google-maps";

import { Circle } from "./Circle";

const DeliveryRadiusMap = ({ latitude, longitude, radius }) => {
  const center = { lat: latitude, lng: longitude };

  let zoom = 14;

  if (radius <= 1500) {
    zoom = 14;
  } else if (radius <= 3000) {
    zoom = 13;
  } else if (radius <= 4000) {
    zoom = 12.5;
  } else if (radius <= 5000) {
    zoom = 12;
  }

  return (
    <div style={{ height: "350px" }}>
      <Map center={center} zoom={zoom}>
        <Marker position={center} />
        <Circle center={center} radius={radius} />
      </Map>
    </div>
  );
};

export default DeliveryRadiusMap;
