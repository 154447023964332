import DeliveryTasksPage from "./pages/DeliveryTasksPage";

const deliveryTaskRoute = {
  path: "delivery-tasks",
  component: <DeliveryTasksPage />,
  allowedKitchenRoles: ["owner", "admin", "rider"],
  isProtected: true,
};

export default deliveryTaskRoute;
