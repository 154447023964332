import axios from "axios";

let base = "/maps/";

const getPlacesAutocomplete = async ({ input }) =>
  (
    await axios.get(base + "places/autocomplete", {
      params: { input },
      skipLoader: true,
    })
  ).data;

const reverseGeocode = async ({ latitude, longitude }) =>
  (
    await axios.get(base + "geocode/reverse", {
      params: { latitude, longitude },
    })
  ).data;

const getPlaceDetails = async ({ placeId }) =>
  (await axios.get(base + `places/${placeId}`)).data;

const data = {
  getPlacesAutocomplete,
  reverseGeocode,
  getPlaceDetails,
};

export default data;
