import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import MenuScheduleCategoryCard from "../components/MenuScheduleCategoryCard";

const MenuScheduleCategoriesPage = () => {
  const navigate = useNavigate();
  const [menuScheduleCategories, setMenuScheduleCategories] = useState([]);

  useEffect(() => {
    const fetchMenuScheduleCategories = async () => {
      try {
        const {
          data: { menuScheduleCategories },
        } = await api.menuSchedules.getMenuScheduleCategories();
        setMenuScheduleCategories(menuScheduleCategories);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMenuScheduleCategories();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Menu Schedules</Title>
        {menuScheduleCategories.map(
          ({ cuisine, mealType, mealTime, menuScheduleDays }) => (
            <MenuScheduleCategoryCard
              key={`${cuisine}-${mealType}-${mealTime}`}
              cuisine={cuisine}
              mealType={mealType}
              mealTime={mealTime}
              menuScheduleDays={menuScheduleDays}
              clickHandler={() =>
                navigate(
                  `/menu-schedules/days/${cuisine}/${mealType}/${mealTime}`,
                )
              }
            />
          ),
        )}
      </Container>
    </>
  );
};

export default MenuScheduleCategoriesPage;
