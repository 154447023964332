import { useCallback, useEffect, useState } from "react";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import MenuCard from "./components/MenuCard";

const MenusPage = () => {
  const navigate = useNavigate();
  const [menus, setMenus] = useState([]);

  const fetchMenus = useCallback(async () => {
    try {
      const {
        data: { menus },
      } = await api.menus.getAll();
      setMenus(menus);
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  return (
    <>
      <Header />
      <Container>
        <Title
          button={
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate("/menus/add")}
            >
              Add Menu
            </Button>
          }
        >
          Menus
        </Title>
        {menus.map((menu) => (
          <MenuCard key={menu._id} menu={menu} fetchMenus={fetchMenus} />
        ))}
        {menus.length === 0 && (
          <NoListFoundText>No menus found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default MenusPage;
