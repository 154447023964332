import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FormComponents from "@/components/FormComponents";
import api from "@/api";
import handleError from "@/utils/handleError";

import {
  formBuilder,
  initialValues,
  validationSchema,
} from "../../utils/menuScheduleFormData";

const CreateMenu = () => {
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime, day } = useParams();
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    formik.resetForm();
    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const {
          data: { menu },
        } = await api.menus.create({
          cuisine,
          mealType,
          mealTime,
          ...values,
        });
        await api.menuSchedules.schedule({
          menuId: menu._id,
          day,
        });
        toast.success("Menu created and scheduled successfully");
        navigate(-1);
      } catch (err) {
        handleError(err);
      } finally {
        closeDialog();
      }
    },
  });

  return (
    <>
      <Button onClick={openDialog} size="small" fullWidth variant="contained">
        Create and Schedule New Menu
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Schedule Menu</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: "5px" }}>
            <FormComponents formik={formik} data={formBuilder} />
            <DialogActions>
              <Button size="small" onClick={closeDialog}>
                Cancel
              </Button>
              <Button size="small" type="submit" variant="contained">
                Schedule
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateMenu;
