import { useState } from "react";
import { Button, Card, CardContent, Chip, Typography } from "@mui/material";

import api from "@/api";
import handleError from "@/utils/handleError";
import { kitchenUserInviteToMuiColor } from "@/utils/mappings";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CancelInviteDialog from "./CancelInviteDialog";

const InviteCard = ({ invite, fetchInvites }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const cancelInvite = async () => {
    try {
      await api.kitchenUserInvites.cancelInvite({
        inviteId: invite._id,
      });
      fetchInvites();
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Card key={invite._id} style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Chip label={invite.role} size="small" />
            <Chip
              size="small"
              label={invite.finalStatus}
              color={kitchenUserInviteToMuiColor[invite.finalStatus]}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">{invite.email}</Typography>
            {invite.finalStatus === "pending" && (
              <Button
                size="small"
                variant="text"
                color="error"
                onClick={openDialog}
              >
                Cancel
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
      <CancelInviteDialog
        open={open}
        setOpen={setOpen}
        cancelHandler={cancelInvite}
      />
    </>
  );
};

export default InviteCard;
