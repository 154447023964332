import { Container } from "@mui/material";

import Header from "@/components/Header";
import Title from "@/components/Title";

import Auth from "./components/Auth";

const AuthPage = () => {
  return (
    <>
      <Header hideRightSection />
      <Container>
        <Title>Kitchen portal</Title>
        <Auth />
      </Container>
    </>
  );
};

export default AuthPage;
