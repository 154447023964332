import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

import Header from "@/components/Header";
import MenuDisplay from "@/components/MenuDisplay";
import Timeline from "@/components/Timeline";

import DeliveryHeader from "./components/DeliveryHeader";
import ExistingRating from "./components/ExistingRating";
import {
  DeliveryPageContextProvider,
  useDeliveryPageCtx,
} from "./contexts/DeliveryPageContext";

const DeliveryPageContent = () => {
  const { date } = useParams();
  const { deliveryStatus, menu, delivery, rating } = useDeliveryPageCtx();
  const { timeline } = delivery;

  return (
    <>
      <Header />
      <Container>
        <DeliveryHeader date={date} deliveryStatus={deliveryStatus} />
        {deliveryStatus !== "invalid" && menu && (
          <MenuDisplay
            cardView
            showMenuLabel
            title={menu.title}
            description={menu.description}
          />
        )}
        {timeline && <Timeline timeline={timeline} />}
        {deliveryStatus === "completed" && rating._id && (
          <ExistingRating rating={rating.rating} review={rating.review} />
        )}
      </Container>
    </>
  );
};

const DeliveryPage = () => {
  return (
    <DeliveryPageContextProvider>
      <DeliveryPageContent />
    </DeliveryPageContextProvider>
  );
};

export default DeliveryPage;
