import { ArrowForward } from "@mui/icons-material";
import { Alert, Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import MenuDisplay from "@/components/MenuDisplay";
import { useData } from "@/contexts/DataContext";
import { verticalComponentMargin } from "@/utils/styleConstants";

const MenuScheduleDayCard = ({ day, menuSchedule }) => {
  const navigate = useNavigate();
  const { cuisine, mealType, mealTime } = useParams();
  const data = useData();

  const clickHandler = async () => {
    navigate(`/menu-schedules/${cuisine}/${mealType}/${mealTime}/${day}`);
  };

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
          {data.dayLabels[day]}
        </Typography>
        {menuSchedule ? (
          <MenuDisplay
            title={menuSchedule.menu.title}
            description={menuSchedule.menu.description}
            hideMenuLabel
          />
        ) : (
          <Alert severity="warning">No Menu Scheduled</Alert>
        )}
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={clickHandler}
          endIcon={<ArrowForward />}
          color={menuSchedule ? "primary" : "warning"}
        >
          {menuSchedule ? "Change Menu" : "Schedule Menu"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuScheduleDayCard;
