import { useState } from "react";
import { Container } from "@mui/material";
import { toast } from "react-toastify";

import BottomDrawer from "@/components/BottomDrawer";
import { OtpForm } from "@/components/OtpVerification";
import api from "@/api";
import handleError from "@/utils/handleError";

import MobileForm from "./MobileForm";

const UpdateMobileDrawer = ({
  open,
  setOpen,
  fetchBasicDetails,
  existingMobile,
}) => {
  const [isOtpTriggered, setIsOtpTriggered] = useState(false);

  const closeDrawer = () => setOpen(false);

  const onOtpTriggered = () => {
    setIsOtpTriggered(true);
  };

  const submitHandler = async (otp) => {
    try {
      await api.kitchenRegistrations.verifyNewMobile({
        otp,
      });
      closeDrawer();
      await fetchBasicDetails();
      toast.success("Mobile number updated successfully");
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <BottomDrawer
      isOpen={open}
      setIsOpen={setOpen}
      title={"Update Owner Mobile"}
    >
      <Container style={{ paddingTop: "20px" }}>
        {!isOtpTriggered ? (
          <MobileForm
            existingMobile={existingMobile}
            onOtpTriggered={onOtpTriggered}
          />
        ) : (
          <OtpForm
            description={
              "Please enter the 6 digit code sent to your new mobile number. Valid for 10 minutes."
            }
            submitHandler={submitHandler}
          />
        )}
      </Container>
    </BottomDrawer>
  );
};

export default UpdateMobileDrawer;
