import { Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import Mobile from "@/components/FormComponents/Mobile";
import api from "@/api";
import handleError from "@/utils/handleError";

const MobileForm = ({ existingMobile, onOtpTriggered }) => {
  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .required("Owner mobile number is required")
        .matches(/^\d+$/, "Owner mobile number must contain only digits")
        .length(10, "Owner mobile number must be exactly 10 digits")
        .test(
          "not-same-as-existing",
          "It shouldn't be same as existing mobile number",
          function (value) {
            return value !== existingMobile;
          },
        ),
    }),
    onSubmit: async ({ mobile }) => {
      try {
        await api.kitchenRegistrations.updateNewMobile({
          mobile,
        });
        onOtpTriggered();
      } catch (error) {
        handleError(error);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Mobile
        formik={formik}
        label={"Enter new owner mobile"}
        state={"mobile"}
      />
      <Button
        size="small"
        type="submit"
        fullWidth
        variant="contained"
        color={
          formik.isValid && formik.values.mobile.length === 10
            ? "success"
            : "primary"
        }
      >
        Send OTP to Verify
      </Button>
    </form>
  );
};

export default MobileForm;
