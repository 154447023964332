export const orderStatusToMuiColor = {
  pending: "warning",
  active: "success",
  cancelled: "error",
  expired: "default",
  completed: "success",
};

export const settlementStatusToColor = {
  pending: "orange",
  completed: "green",
  failed: "red",
};

export const expiryReasonMapping = {
  payment: "Payment timed out",
  miss: "Miss limit breach by kitchen",
  end: "Order End date crossed",
};

export const deliveryStatusToColorMapping = {
  deliverable: "lightgreen",
  nonDeliverable: "lightcoral",
  completed: "#2e7d32",
  skipped: "#0288d1",
  missed: "#ed6c02",
  cancelled: "#d32f2f",
  expired: "gray",
  dispatched: "green",
  invalid: "crimson",
};

export const deliverystatusToMuiColor = {
  pending: "warning",
  dispatched: "info",
  completed: "success",
  missed: "error",
};

export const kitchenUserInviteToMuiColor = {
  pending: "warning",
  cancelled: "error",
  accepted: "success",
  declined: "error",
};
