import { Container } from "@mui/material";

import Header from "@/components/Header";
import {
  AddressDisplay,
  BankDetailsDisplay,
  BasicDetailsDisplay,
  ConfigurationDisplay,
  LegalDetailsDisplay,
  PricingDisplay,
} from "@/components/KitchenDetailsDisplay";
import Title from "@/components/Title";
import { useKitchen } from "@/contexts/KitchenContext";

const KitchenPage = () => {
  const { kitchen } = useKitchen();

  return (
    <>
      <Header />
      <Container>
        <Title>{kitchen.name}</Title>
        <BasicDetailsDisplay
          kitchenName={kitchen.name}
          kitchenTagline={kitchen.tagline}
          ownerName={kitchen.ownerDetails.name}
          ownerPhoneNumber={kitchen.ownerDetails.mobileDetails.mobile}
        />
        <AddressDisplay address={kitchen.address} />
        <ConfigurationDisplay configuration={kitchen.configuration} />
        <PricingDisplay
          configuration={kitchen.configuration}
          pricing={kitchen.pricing}
        />
        <BankDetailsDisplay bankDetails={kitchen.bankDetails} />
        <LegalDetailsDisplay legalDetails={kitchen.legalDetails} />
      </Container>
    </>
  );
};

export default KitchenPage;
