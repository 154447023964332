import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import CreateOrSelectMenu from "./components/CreateOrSelectMenu";
import MenuScheduleForm from "./components/MenuScheduleForm";
import MenuScheduleInfo from "./components/MenuScheduleInfo";

const MenuSchedulePage = () => {
  const { cuisine, mealType, mealTime } = useParams();
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    const fetchAllMenus = async () => {
      try {
        const {
          data: { menus },
        } = await api.menus.getAll({
          filters: {
            cuisine,
            mealType,
            mealTime,
          },
        });
        setMenus(menus);
      } catch (error) {
        handleError(error);
      }
    };
    fetchAllMenus();
  }, [cuisine, mealType, mealTime]);

  return (
    <>
      <Header />
      <Container>
        <Title>Menu Schedule</Title>
        <MenuScheduleInfo />
        {menus.length === 0 && <MenuScheduleForm />}
        {menus.length > 0 && <CreateOrSelectMenu menus={menus} />}
      </Container>
    </>
  );
};

export default MenuSchedulePage;
