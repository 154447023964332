import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";

import FormComponents from "@/components/FormComponents";

const validationSchema = yup.object({
  reason: yup.string().required("Reason is required"),
});

const MissDeliveryDialog = ({
  open,
  setOpen,
  submitHandler,
  description,
  submitButtonName,
}) => {
  const formik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema,
    onSubmit: submitHandler,
  });

  const closeDialog = () => {
    formik.resetForm();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={closeDialog}>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Typography style={{ marginBottom: "8px" }}>{description}</Typography>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "input",
                label: "Reason",
                state: "reason",
                multiline: true,
                rows: 3,
              },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button type="button" color="success" onClick={closeDialog}>
            Back
          </Button>
          <Button type="submit" color="error">
            {submitButtonName}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MissDeliveryDialog;
