import { useState } from "react";
import { Visibility } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";

import PricePreviewDrawer from "@/components/PricePreviewDrawer";
import { verticalComponentMargin } from "@/utils/styleConstants";

const BasePriceInputAndPricePreview = ({
  price,
  setPrice,
  configuration,
  pricing,
}) => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  return (
    <>
      <div
        style={{
          margin: verticalComponentMargin,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ width: "50%" }}
          size="small"
          label="Base Price (₹)"
          value={price}
          onChange={(e) => {
            const input = e.target.value;
            if (input.length > 3) return;
            const regex = /^[0-9]*$/;
            if (regex.test(input)) {
              setPrice(input);
            }
          }}
        />
        <Button
          size="small"
          disabled={!price}
          startIcon={<Visibility />}
          onClick={openDrawer}
        >
          Price preview
        </Button>
      </div>
      {open && (
        <PricePreviewDrawer
          isOpen={open}
          setIsOpen={setOpen}
          configuration={configuration}
          pricing={pricing}
        />
      )}
    </>
  );
};

export default BasePriceInputAndPricePreview;
