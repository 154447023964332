import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";

import { useData } from "@/contexts/DataContext";
import { convertToAMPM, isCurrentDate } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";
import {
  isTimeAfterRange,
  isTimeBeforeRange,
  isTimeInRange,
} from "@/utils/timeRangeUtils";

const DeliveryWindowAlert = () => {
  const { date, mealTime } = useParams();
  const { mealTimeDeliveryWindow } = useData();
  const range = mealTimeDeliveryWindow[mealTime];

  if (!isCurrentDate(date)) return null;

  const startTime = convertToAMPM(range.start);
  const endTime = convertToAMPM(range.end);
  let severity = "";
  let description = "";

  if (isTimeBeforeRange(range)) {
    severity = "info";
    description = `Delivery window will be open from ${startTime} to ${endTime}`;
  } else if (isTimeInRange(range)) {
    severity = "success";
    description = `Delivery window is active and will be closed at ${endTime}`;
  } else if (isTimeAfterRange(range)) {
    severity = "error";
    description = `Delivery window is closed at ${endTime}`;
  } else {
    return null;
  }

  return (
    <Alert severity={severity} style={{ margin: verticalComponentMargin }}>
      {description}
    </Alert>
  );
};

export default DeliveryWindowAlert;
