import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import DateBar from "@/components/DateBar";
import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import TaskCard from "./components/TaskCard";

const DispatchTasksPage = () => {
  const [date, setDate] = useState(null);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getAllTasks = async () => {
      try {
        const {
          data: { dispatchTasks },
        } = await api.dispatchTasks.getAllDispatchTasks({
          date,
        });
        setTasks(dispatchTasks);
      } catch (error) {
        handleError(error);
      }
    };
    if (date) {
      getAllTasks();
    }
  }, [date]);

  return (
    <>
      <Header />
      <Container>
        <Title>Dispatch Tasks</Title>
        <DateBar onDateChange={setDate} />
        {tasks.map(
          ({
            cuisine,
            mealType,
            mealTime,
            numberOfMeals,
            numberOfOrders,
            menuSchedule,
          }) => {
            return (
              <TaskCard
                key={`${cuisine}-${mealType}-${mealTime}`}
                date={date}
                cuisine={cuisine}
                mealType={mealType}
                mealTime={mealTime}
                numberOfOrders={numberOfOrders}
                numberOfMeals={numberOfMeals}
                menuSchedule={menuSchedule}
              />
            );
          },
        )}
        {tasks.length === 0 && (
          <NoListFoundText>No delivery tasks found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default DispatchTasksPage;
