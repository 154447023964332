import { ArrowForward } from "@mui/icons-material";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CategoryTitle from "@/components/CategoryTitle";
import MenuDisplay from "@/components/MenuDisplay";
import { verticalComponentMargin } from "@/utils/styleConstants";

const TaskCard = ({
  date,
  cuisine,
  mealType,
  mealTime,
  numberOfOrders,
  numberOfMeals,
  menuSchedule,
}) => {
  const navigate = useNavigate();

  const clickHandler = async () => {
    navigate(`/dispatch-tasks/${date}/${cuisine}/${mealType}/${mealTime}`);
  };

  return (
    <Card
      style={{
        margin: verticalComponentMargin,
      }}
    >
      <CardContent>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
        />
        <MenuDisplay
          title={menuSchedule?.menu.title}
          description={menuSchedule?.menu.description}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          <Typography variant="subtitle1">Orders : {numberOfOrders}</Typography>
          <Typography variant="subtitle1">Meals : {numberOfMeals}</Typography>
        </div>
        <Button
          size="small"
          fullWidth
          variant="contained"
          onClick={clickHandler}
          endIcon={<ArrowForward />}
        >
          View details
        </Button>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
