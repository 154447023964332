import { Container } from "@mui/material";

import Header from "@/components/Header";

import Address from "./components/Address";
import DeliveryManagement from "./components/DeliveryManagement";
import OrderAlert from "./components/OrderAlert";
import OrderHeader from "./components/OrderHeader";
import OrderSummary from "./components/OrderSummary";
import PaymentSummary from "./components/PaymentSummary";
import SettlementSummary from "./components/SettlementSummary";
import { OrderPageContextProvider } from "./contexts/OrderPageContext";

const OrderPage = () => {
  return (
    <OrderPageContextProvider>
      <Header />
      <Container>
        <OrderHeader />
        <OrderAlert />
        <OrderSummary />
        <DeliveryManagement />
        <Address />
        <PaymentSummary />
        <SettlementSummary />
      </Container>
    </OrderPageContextProvider>
  );
};

export default OrderPage;
