import { isTimeInRange } from "@/utils/timeRangeUtils";

const getMealTimesInDeliveryWindow = ({ mealTimes, deliveryWindow }) => {
  const mealTimesInDeliveryWindow = [];

  mealTimes.forEach((mealTime) => {
    if (isTimeInRange(deliveryWindow[mealTime])) {
      mealTimesInDeliveryWindow.push(mealTime);
    }
  });

  return mealTimesInDeliveryWindow;
};

export default getMealTimesInDeliveryWindow;
