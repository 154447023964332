import { useParams } from "react-router-dom";

import { isCurrentDate } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

import DispatchAllButton from "./DispatchAllButton";
import MissAllButton from "./MissAllButton";
import { useDispatchTaskPageContext } from "../../contexts/DispatchTaskPageContext";

const ActionButtons = () => {
  const { date } = useParams();
  const { isDeliveryWindowOpen, fetchDeliveries } =
    useDispatchTaskPageContext();

  if (!isCurrentDate(date) || !isDeliveryWindowOpen) return null;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "10px",
        margin: verticalComponentMargin,
      }}
    >
      <DispatchAllButton fetchDeliveries={fetchDeliveries} />
      <MissAllButton fetchDeliveries={fetchDeliveries} />
    </div>
  );
};

export default ActionButtons;
