import React from "react";
import { Marker, Map as VisMap } from "@vis.gl/react-google-maps";

import { verticalComponentMargin } from "@/utils/styleConstants";

const Map = ({ latitude, longitude, draggable, handleDragEnd }) => {
  const markerLocation = { lat: latitude, lng: longitude };

  return (
    <div
      style={{
        width: "100%",
        height: "350px",
        margin: verticalComponentMargin,
      }}
    >
      <VisMap defaultCenter={markerLocation} defaultZoom={18}>
        <Marker
          position={markerLocation}
          draggable={draggable}
          onDragEnd={(prop) =>
            handleDragEnd({
              latitude: prop.latLng.lat(),
              longitude: prop.latLng.lng(),
            })
          }
        />
      </VisMap>
    </div>
  );
};

export default Map;
