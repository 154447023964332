import { Card, CardContent, Typography } from "@mui/material";

import GreyCover from "./GreyCover";

const MenuDisplay = ({
  title,
  description,
  cardView,
  showMenuLabel,
  marginYAxis,
}) => {
  if (!title || !description) return null;

  let margin = "10px 0px";
  if (marginYAxis) {
    margin = `${marginYAxis} 0px`;
  }

  const content = (
    <>
      {showMenuLabel && (
        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
          Menu
        </Typography>
      )}
      <Typography variant="body1" display={"inline"}>
        {title} :{" "}
      </Typography>
      <Typography variant="body2" display={"inline"} color={"text.secondary"}>
        {description}
      </Typography>
    </>
  );

  if (cardView) {
    return (
      <Card style={{ margin }}>
        <CardContent>{content}</CardContent>
      </Card>
    );
  } else {
    return <GreyCover marginYAxis={marginYAxis}>{content}</GreyCover>;
  }
};

export default MenuDisplay;
