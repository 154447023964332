import { ArrowForward } from "@mui/icons-material";
import { Button, Card, CardContent, Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { formatTimestamp } from "@/utils/dateUtils";

const colorMapping = {
  pending: "warning",
  completed: "success",
  failed: "error",
};

const SettlementCard = ({ settlement }) => {
  const navigate = useNavigate();
  return (
    <Card style={{ margin: "15px 0px" }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
        >
          <div>
            <Typography variant={"h6"}>
              {"₹" + settlement.settlementAmount}
            </Typography>
            <Typography
              color={"gray"}
              variant={"caption"}
              style={{ marginBottom: "10px" }}
            >
              {formatTimestamp(settlement.createdAt)}
            </Typography>
          </div>
          <Chip
            size="small"
            label={settlement.status.toUpperCase()}
            color={colorMapping[settlement.status]}
          />
        </div>
        <Button
          fullWidth
          variant="contained"
          size="small"
          endIcon={<ArrowForward />}
          onClick={() => navigate(`/orders/${settlement.order}`)}
        >
          View Order Details
        </Button>
      </CardContent>
    </Card>
  );
};

export default SettlementCard;
