import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { AppBar, Container, Toolbar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/AuthContext";

import styles from "./Header.module.css";
import Logo from "./Logo";
import UserAccountIcon from "./UserAccountIcon";

const Header = ({ hideBackButton }) => {
  const authCtx = useAuth();
  const navigate = useNavigate();

  return (
    <AppBar position="sticky">
      <Container>
        <Toolbar className={styles.header}>
          <div className={styles.left}>
            {!hideBackButton && (
              <ArrowBackIcon
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              />
            )}
          </div>
          <div className={styles.center}>
            <Logo />
          </div>
          <div className={styles.right}>
            {authCtx.isLoggedIn && <UserAccountIcon />}
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
