import { Alert, Card, CardContent } from "@mui/material";

import ColonText from "@/components/ColonText";
import Title from "@/components/Title";
import { formatTimestamp } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const PaymentSummary = () => {
  const { order } = useOrderPageContext();

  if (!order.payment) return null;

  const { payment } = order;
  const { amount, finalStatus, isLatePaymentReceived } = payment;

  return (
    <div>
      <Title alignLeft>Payment</Title>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <ColonText first={"Amount"} second={"₹" + amount} />
          <ColonText first={"Status"} second={finalStatus} />
          {payment.finalStatus === "expired" && (
            <ColonText
              first={"Payment expired at"}
              second={formatTimestamp(payment.expiry)}
            />
          )}
          {payment.finalStatus === "completed" && (
            <ColonText
              first={"Payment completed at"}
              second={formatTimestamp(
                payment.timeline[payment.timeline.length - 1].timestamp,
              )}
            />
          )}
          {isLatePaymentReceived && (
            <Alert severity="warning">
              Late payment received and refunded.
            </Alert>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default PaymentSummary;
