import { useEffect, useState } from "react";
import { Container } from "@mui/material";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import SettlementCard from "./components/SettlementCard";

const SettlementsPage = () => {
  const [settlements, setSettlements] = useState([]);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        const {
          data: { settlements },
        } = await api.settlements.getAllSettlements();
        setSettlements(settlements);
      } catch (error) {
        handleError(error);
      }
    };
    fetchSettlements();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Settlements</Title>
        {settlements.map((settlement) => (
          <SettlementCard key={settlement._id} settlement={settlement} />
        ))}
        {settlements.length === 0 && (
          <NoListFoundText>No settlements found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default SettlementsPage;
