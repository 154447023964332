import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const LocationSearch = ({ searchValue, setSearchValue }) => {
  const handleClear = () => {
    setSearchValue("");
  };

  return (
    <TextField
      style={{ marginBottom: "20px" }}
      fullWidth
      size="small"
      placeholder="Search Location"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearch;
