import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

import api from "@/api";
import handleError from "@/utils/handleError";

const CompleteDeliveryButton = ({ deliveryId, fetchNearestDelivery }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const completeDelivery = async () => {
    try {
      await api.deliveries.complete({
        deliveryId,
      });
      await fetchNearestDelivery();
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="success"
        onClick={openDialog}
      >
        Complete
      </Button>
      <Dialog open={open} onClose={closeDialog}>
        <DialogContent>
          <Typography>
            Are you sure you want to complete this delivery?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={closeDialog}>
            Back
          </Button>
          <Button color="success" onClick={completeDelivery}>
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompleteDeliveryButton;
