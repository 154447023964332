import { Alert } from "@mui/material";

const HelperAlert = () => {
  return (
    <Alert severity="info" style={{ margin: "20px 0px 30px 0px" }}>
      Set a base price and adjust the multipliers. The final price of a category
      is calculated by adding up the base price with the contributions from
      respective multipliers. A multiplier above 1 increases it (e.g., 1.2 gives
      +20% of the base price), while a multiplier below 1 decreases it (e.g.,
      0.8 gives -20% of the base price). You can preview the prices for
      different categories from below.
    </Alert>
  );
};

export default HelperAlert;
