import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import SendIcon from "@mui/icons-material/Send";
import StoreIcon from "@mui/icons-material/Store";
import { Backdrop, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "@/contexts/AuthContext";
import { useKitchen } from "@/contexts/KitchenContext";

const menuItems = [
  {
    allowedKitchenRoles: ["owner", "admin", "chef"],
    icon: <SendIcon />,
    label: "Dispatch Tasks",
    path: "/dispatch-tasks",
  },
  {
    allowedKitchenRoles: ["owner", "admin", "rider"],
    icon: <DeliveryDiningIcon />,
    label: "Delivery Tasks",
    path: "/delivery-tasks",
  },
  {
    allowedKitchenRoles: ["owner", "admin", "chef"],
    icon: <RestaurantMenuIcon />,
    label: "Menus",
    path: "/menus",
  },
  {
    allowedKitchenRoles: ["owner", "admin", "chef"],
    icon: <PendingActionsIcon />,
    label: "Menu Schedules",
    path: "/menu-schedules/categories",
  },
  {
    allowedKitchenRoles: ["owner", "admin"],
    icon: <ListAltIcon />,
    label: "Orders",
    path: "/orders",
  },
  {
    allowedKitchenRoles: ["owner", "admin"],
    icon: <CreditScoreIcon />,
    label: "Settlements",
    path: "/settlements",
  },
  {
    allowedKitchenRoles: ["owner", "admin"],
    icon: <ManageAccountsIcon />,
    label: "Kitchen users",
    path: "/kitchen-users",
  },
  {
    label: "Kitchen",
    path: "/kitchen",
    icon: <StoreIcon />,
    allowedKitchenRoles: ["owner", "admin"],
  },
];

const PopupMenu = ({ anchorEl, handleMenuClose }) => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const isOpen = Boolean(anchorEl);
  const { kitchenRole } = useKitchen();

  return (
    <>
      <Backdrop open={isOpen} onClick={handleMenuClose} style={{ zIndex: 2 }} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            width: "230px",
          },
        }}
      >
        {menuItems
          .filter((item) => item.allowedKitchenRoles.includes(kitchenRole))
          .map((item, index) => (
            <MenuItem key={index} onClick={() => navigate(item.path)}>
              {item.icon}
              <span style={{ width: "10px" }}></span>
              {item.label}
            </MenuItem>
          ))}
        <MenuItem
          onClick={() => {
            authCtx.logout();
            toast.success("Logged out successfully!");
            handleMenuClose();
          }}
        >
          <LogoutIcon />
          <span style={{ width: "10px" }}></span>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default PopupMenu;
