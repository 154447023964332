import {
  Button,
  capitalize,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

const InviteCard = ({ kitchenUserInvite }) => {
  const navigate = useNavigate();
  const { fetchMyKitchenDetails } = useKitchen();
  const inviteId = kitchenUserInvite._id;

  const handleAccept = async () => {
    try {
      await api.kitchenUserInvites.acceptInvite({
        inviteId,
      });
      toast.success("Invite accepted");
      await fetchMyKitchenDetails();
    } catch (error) {
      handleError(error);
    }
  };

  const handleDecline = async (id) => {
    try {
      await api.kitchenUserInvites.declineInvite({
        inviteId,
      });
      toast.success("Invite declined");
      navigate("/", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Card
      key={kitchenUserInvite._id}
      style={{ margin: verticalComponentMargin }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography color="text.secondary">
            <strong>{kitchenUserInvite.kitchen.name}</strong> is inviting you to
            join them as a <strong>{capitalize(kitchenUserInvite.role)}</strong>
          </Typography>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Button
            size="small"
            variant="contained"
            color="success"
            onClick={handleAccept}
          >
            Accept
          </Button>
          <Button
            size="small"
            variant="contained"
            color="error"
            onClick={handleDecline}
          >
            Decline
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

export default InviteCard;
