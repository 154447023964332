import { useState } from "react";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MissDeliveryDialog from "@/components/MissDeliveryDialog";
import api from "@/api";
import handleError from "@/utils/handleError";

const MissAllButton = ({ fetchDeliveries }) => {
  const { date, cuisine, mealType, mealTime } = useParams();

  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => {
    setOpen(false);
  };

  const submitHandler = async (values) => {
    try {
      await api.dispatchTasks.missAllDeliveries({
        date,
        cuisine,
        mealType,
        mealTime,
        reason: values.reason,
      });
      await fetchDeliveries();
      toast.success("Deliveries missed successfully");
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Button
        onClick={openDialog}
        size="small"
        variant="contained"
        color="error"
      >
        Miss All
      </Button>
      <MissDeliveryDialog
        description={"Are you sure you want to miss all pending deliveries?"}
        open={open}
        setOpen={setOpen}
        submitButtonName={"Miss All"}
        submitHandler={submitHandler}
      />
    </>
  );
};

export default MissAllButton;
