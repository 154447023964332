import { Navigate, useLocation } from "react-router-dom";

import { useKitchen } from "@/contexts/KitchenContext";

const KitchenGuard = ({ children, allowedKitchenRoles }) => {
  const { kitchen, kitchenRole, loading } = useKitchen();
  const location = useLocation();

  if (!allowedKitchenRoles) {
    allowedKitchenRoles = [];
  }

  if (loading) {
    return null;
  }

  if (kitchen._id) {
    if (allowedKitchenRoles.includes(kitchenRole)) {
      return children;
    } else {
      if (location.pathname === "/") {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    }
  } else {
    if (allowedKitchenRoles.length) {
      if (location.pathname === "/") {
        return children;
      } else {
        return <Navigate to="/" />;
      }
    } else {
      return children;
    }
  }
};

export default KitchenGuard;
