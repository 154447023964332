import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./common/CardHeader";

const BasicDetailsDisplay = ({
  isEditable,
  kitchenName,
  kitchenTagline,
  ownerName,
  ownerPhoneNumber,
}) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title={"Kitchen Details"}
          editPath={isEditable && "/kitchen-registration/basic-details"}
        />
        <LabelValueText label="Kitchen Name" value={kitchenName} />
        {kitchenTagline && (
          <LabelValueText label="Kitchen Tagline" value={kitchenTagline} />
        )}
        <LabelValueText label="Owner Name" value={ownerName} />
        <LabelValueText label="Owner Phone Number" value={ownerPhoneNumber} />
      </CardContent>
    </Card>
  );
};

export default BasicDetailsDisplay;
