import { Forward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import { isCurrentDate } from "@/utils/dateUtils";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useDispatchTaskPageContext } from "../contexts/DispatchTaskPageContext";

const GoToDeliveryTasksPageButton = () => {
  const { date } = useParams();
  const navigate = useNavigate();
  const { isDeliveryWindowOpen } = useDispatchTaskPageContext();

  if (!isCurrentDate(date) || !isDeliveryWindowOpen) return null;

  return (
    <div style={{ margin: verticalComponentMargin }}>
      <Button
        size="small"
        variant="outlined"
        color="info"
        onClick={() => navigate("/delivery-tasks")}
        endIcon={<Forward />}
      >
        Go to Delivery tasks page to complete the deliveries
      </Button>
    </div>
  );
};

export default GoToDeliveryTasksPageButton;
