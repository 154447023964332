import AddressSummary from "@/components/AddressSummary";
import Title from "@/components/Title";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const Address = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      <Title alignLeft>Address</Title>
      <AddressSummary address={order.address} />
    </>
  );
};

export default Address;
