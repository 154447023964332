import { Alert } from "@mui/material";

import { formatTimestamp } from "@/utils/dateUtils";
import { expiryReasonMapping } from "@/utils/mappings";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const OrderAlert = () => {
  const { order } = useOrderPageContext();
  const style = { margin: verticalComponentMargin };
  return (
    <>
      {order.finalStatus === "pending" && (
        <Alert
          severity="warning"
          style={style}
        >{`User need to complete the payment before ${formatTimestamp(
          order.payment.expiry,
        )} to activate the order`}</Alert>
      )}
      {order.finalStatus === "expired" && (
        <Alert severity="error" style={style}>
          {`Expiry reason:
              ${expiryReasonMapping[order.expiryDetails.reason]}
            `}
        </Alert>
      )}
      {order.status === "active" && order.finalStatus === "expired" && (
        <Alert severity="warning" style={style}>
          {`Your settlement will be processed soon`}
        </Alert>
      )}
    </>
  );
};

export default OrderAlert;
