import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import InviteCard from "./components/InviteCard";

const KitchenUserInvitesPage = () => {
  const navigate = useNavigate();
  const [kitchenUserInvites, setKitchenUserInvites] = useState([]);

  const fetchInvites = async () => {
    try {
      const res = await api.kitchenUserInvites.getAllSentInvites();
      const { kitchenUserInvites } = res.data;
      setKitchenUserInvites(kitchenUserInvites);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title
          button={
            <Button
              size="small"
              variant="contained"
              onClick={() => navigate("/kitchen-users/invites/send")}
              endIcon={<ArrowForward />}
            >
              Send invite
            </Button>
          }
        >
          Invites
        </Title>
        {kitchenUserInvites.map((invite) => (
          <InviteCard
            key={invite._id}
            invite={invite}
            fetchInvites={fetchInvites}
          />
        ))}
        {kitchenUserInvites.length === 0 && (
          <NoListFoundText>No invites found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default KitchenUserInvitesPage;
