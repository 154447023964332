import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import BottomDrawer from "@/components/BottomDrawer";
import FormComponents from "@/components/FormComponents";

const addressBuilder = [
  {
    type: "input",
    label: "Flat, House no., Building, Company, Apartment*",
    state: "building",
    value: "",
  },
  {
    type: "input",
    label: "Area, Street, Sector, Village*",
    state: "area",
    value: "",
  },
  {
    type: "input",
    label: "Landmark (optional)",
    state: "landmark",
    value: "",
  },
];

const validationSchema = Yup.object({
  building: Yup.string()
    .transform((value) => value.trim())
    .required("Building information is required"),
  area: Yup.string()
    .transform((value) => value.trim())
    .required("Area information is required"),
  landmark: Yup.string()
    .transform((value) => value.trim())
    .notRequired(),
});

const AddressDetailsDrawer = ({
  isOpen,
  setIsOpen,
  title,
  building = "",
  area = "",
  landmark = "",
  submitHandler,
}) => {
  const formik = useFormik({
    initialValues: {
      building,
      area,
      landmark,
    },
    validationSchema,
    onSubmit: submitHandler,
  });

  return (
    <BottomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={title || "Address Details"}
    >
      <Container>
        <form style={{ marginTop: "30px" }} onSubmit={formik.handleSubmit}>
          <FormComponents formik={formik} data={addressBuilder} />
          <Button fullWidth variant="contained" color="success" type="submit">
            Submit
          </Button>
        </form>
      </Container>
    </BottomDrawer>
  );
};

export default AddressDetailsDrawer;
