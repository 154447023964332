import { Alert, Card, CardContent, Container, Typography } from "@mui/material";

import CategoryTitle from "@/components/CategoryTitle";
import { useData } from "@/contexts/DataContext";
import { verticalComponentMargin } from "@/utils/styleConstants";

import generatePricingCategories from "./utils/generatePricingCategories";
import BottomDrawer from "../BottomDrawer";

const PricePreviewDrawer = ({ isOpen, setIsOpen, configuration, pricing }) => {
  const data = useData();
  const { cuisines, mealTypes, mealTimes, periods } = configuration;

  const categories = generatePricingCategories({
    data,
    cuisines,
    mealTypes,
    mealTimes,
    periods,
    pricing,
  });

  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title={"Price Preview"}>
      <Container>
        <div style={{ paddingBottom: "50px" }}>
          <Alert severity="info" style={{ margin: verticalComponentMargin }}>
            5 % of the below prices will be deducted as platform comission
          </Alert>
          {categories.map((category, index) => (
            <Card key={index} style={{ margin: verticalComponentMargin }}>
              <CardContent>
                <CategoryTitle
                  cuisine={category.cuisine}
                  mealType={category.mealType}
                  mealTime={category.mealTime}
                  period={category.period}
                />
                <Typography variant="body1">
                  Price: ₹{category.price}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Container>
    </BottomDrawer>
  );
};

export default PricePreviewDrawer;
