import { Card, CardContent, Typography } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

import { useDispatchTaskPageContext } from "../contexts/DispatchTaskPageContext";

const findLength = (deliveries, status) =>
  deliveries.filter((delivery) => delivery.status === status).length;

const SummaryItem = ({ label, value }) => (
  <div
    style={{
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      marginBottom: "8px",
    }}
  >
    <Typography>{label}:</Typography>
    <Typography>{value}</Typography>
  </div>
);

const DeliverySummary = () => {
  const { deliveries } = useDispatchTaskPageContext();
  const totalDeliveries = deliveries.length;
  const pendingDeliveries = findLength(deliveries, "pending");
  const dispatchedDeliveries = findLength(deliveries, "dispatched");
  const missedDeliveries = findLength(deliveries, "missed");
  const completedDeliveries = findLength(deliveries, "completed");

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <SummaryItem label="Total" value={totalDeliveries} />
        <SummaryItem label="Pending" value={pendingDeliveries} />
        <SummaryItem label="Dispatched" value={dispatchedDeliveries} />
        <SummaryItem label="Missed" value={missedDeliveries} />
        <SummaryItem label="Completed" value={completedDeliveries} />
      </CardContent>
    </Card>
  );
};

export default DeliverySummary;
