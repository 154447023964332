const GreyCover = ({ children, marginYAxis }) => {
  let margin = "10px 0px";
  if (marginYAxis) {
    margin = `${marginYAxis} 0px`;
  }
  return (
    <div
      style={{
        background: "#f5f5f5",
        padding: "10px",
        borderRadius: "10px",
        margin,
      }}
    >
      {children}
    </div>
  );
};

export default GreyCover;
