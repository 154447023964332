import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const CardHeader = ({ title, editPath }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      {editPath && (
        <Button
          size="small"
          variant="contained"
          onClick={() => navigate(editPath)}
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default CardHeader;
