import { useEffect, useState } from "react";
import { Button, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import OrDivider from "@/components/OrDivider";
import api from "@/api";
import handleError from "@/utils/handleError";

import InviteCard from "./components/InviteCard";

const KitchenUserInvitesReceivedPage = () => {
  const navigate = useNavigate();
  const [kitchenUserInvites, setKitchenUserInvites] = useState([]);

  useEffect(() => {
    const fetchInvites = async () => {
      try {
        const {
          data: { kitchenUserInvites },
        } = await api.kitchenUserInvites.getAllActiveReceivedInvites();
        setKitchenUserInvites(kitchenUserInvites);
      } catch (error) {
        handleError(error);
      }
    };
    fetchInvites();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Button
          size="small"
          variant="contained"
          fullWidth
          style={{ marginTop: "20px" }}
          onClick={() => navigate("/kitchen-registration")}
        >
          Register your own kitchen
        </Button>
        <OrDivider />
        <Typography variant="subtitle1" textAlign={"center"}>
          You can accept or decline requests from other kitchens
        </Typography>
        {kitchenUserInvites.map((kitchenUserInvite) => (
          <InviteCard
            key={kitchenUserInvite._id}
            kitchenUserInvite={kitchenUserInvite}
          />
        ))}
        {kitchenUserInvites.length === 0 && (
          <NoListFoundText>No invites found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default KitchenUserInvitesReceivedPage;
