import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { Card, CardContent, Typography } from "@mui/material";

import GreyCover from "./GreyCover";
import IconText from "./IconText";

const AddressSummary = ({ address }) => {
  const {
    label,
    contactName,
    contactNumber,
    building,
    area,
    landmark,
    formattedAddress,
  } = address;
  return (
    <Card style={{ margin: "20px 0" }}>
      <CardContent>
        <IconText icon={<PersonIcon />} text={contactName} />
        <IconText icon={<CallIcon />} text={contactNumber} />
        <IconText
          icon={<HomeIcon />}
          text={`${label} - ${building}${area && ", " + area} ${landmark && ", " + landmark} `}
        />
        <GreyCover>
          <Typography variant="caption">{formattedAddress}</Typography>
        </GreyCover>
      </CardContent>
    </Card>
  );
};

export default AddressSummary;
