import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";

const GoogleSignin = () => {
  const navigate = useNavigate();
  const authCtx = useAuth();

  const handleCallbackResponse = useCallback(
    async (response) => {
      try {
        if (response.credential) {
          const res = await api.auth.googleSignin({
            tokenId: response.credential,
          });
          authCtx.login({ token: res.token, user: res.data.user });
          toast.success("Logged in successfully.");
          navigate("/", { replace: true });
        } else {
          console.error(response);
          toast.error("Something went wrong. Please try again later.");
        }
      } catch (err) {
        handleError(err);
      }
    },
    [authCtx, navigate],
  );

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });

    window.google.accounts.id.renderButton(
      document.getElementById("google-signin-button"),
      {
        theme: "filled_blue",
        size: "medium",
        shape: "rectangular",
      },
    );
  }, [handleCallbackResponse]);

  return <div id="google-signin-button"></div>;
};

export default GoogleSignin;
