export const objectToFormData = (object) => {
  const formData = new FormData();

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      formData.append(key, object[key]);
    }
  }

  return formData;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};

export const filterObj = (obj, ...allowedKeys) => {
  const newObj = {};
  Object.keys(obj).forEach((el) => {
    if (allowedKeys.includes(el)) newObj[el] = obj[el];
  });
  return newObj;
};
