import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MissDeliveryDialog from "@/components/MissDeliveryDialog";
import NoListFoundText from "@/components/NoListFoundText";
import api from "@/api";
import handleError from "@/utils/handleError";

import DeliveryCard from "./DeliveryCard";
import { useDispatchTaskPageContext } from "../../contexts/DispatchTaskPageContext";

const DeliveryList = () => {
  const { date } = useParams();
  const { deliveries, fetchDeliveries, isDeliveryWindowOpen } =
    useDispatchTaskPageContext();
  const [dispatchDialogOpen, setDispatchDialogOpen] = useState(false);
  const [missDialogOpen, setMissDialogOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");

  const closeDispatchDialog = () => {
    setDispatchDialogOpen(false);
  };

  const closeMissDialog = () => {
    setMissDialogOpen(false);
  };

  const dispatchButtonClickHandler = (orderId) => {
    setSelectedOrderId(orderId);
    setDispatchDialogOpen(true);
  };

  const missButtonClickHandler = (orderId) => {
    setSelectedOrderId(orderId);
    setMissDialogOpen(true);
  };

  const dispatchHandler = async () => {
    try {
      await api.dispatchTasks.dispatchDelivery({
        orderId: selectedOrderId,
        date,
      });
      await fetchDeliveries();
      toast.success("Delivery dispatched successfully");
    } catch (error) {
      handleError(error);
    } finally {
      closeDispatchDialog();
    }
  };

  const missHandler = async (values) => {
    try {
      await api.dispatchTasks.missDelivery({
        date,
        orderId: selectedOrderId,
        reason: values.reason,
      });
      toast.success("Delivery missed successfully");
      await fetchDeliveries();
    } catch (error) {
      handleError(error);
    } finally {
      closeMissDialog();
    }
  };

  return (
    <>
      {deliveries.map((delivery) => (
        <DeliveryCard
          key={delivery.orderId}
          delivery={delivery}
          dispatchHandler={dispatchButtonClickHandler}
          missHandler={missButtonClickHandler}
          isDeliveryWindowOpen={isDeliveryWindowOpen}
        />
      ))}
      {deliveries.length === 0 && (
        <NoListFoundText>No deliveries found</NoListFoundText>
      )}
      <Dialog open={dispatchDialogOpen} onClose={closeDispatchDialog}>
        <DialogContent>
          <Typography>
            Are you sure you want to dispatch this delivery?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={closeDispatchDialog}>
            Back
          </Button>
          <Button color="success" onClick={dispatchHandler}>
            Dispatch
          </Button>
        </DialogActions>
      </Dialog>
      <MissDeliveryDialog
        open={missDialogOpen}
        setOpen={setMissDialogOpen}
        description={"Are you sure you want to miss this delivery?"}
        submitButtonName={"Miss"}
        submitHandler={missHandler}
      />
    </>
  );
};

export default DeliveryList;
