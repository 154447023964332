import { Fragment } from "react";
import { Divider, List, ListItem, ListItemText } from "@mui/material";

const SuggestionList = ({ suggestions, onSuggestionSelect }) => {
  return (
    <List>
      {suggestions.map((suggestion, index) => (
        <Fragment key={suggestion?.placePrediction?.placeId}>
          <ListItem button onClick={() => onSuggestionSelect(suggestion)}>
            <ListItemText
              primary={
                suggestion?.placePrediction?.structuredFormat?.mainText?.text
              }
              secondary={
                suggestion?.placePrediction?.structuredFormat?.secondaryText
                  ?.text
              }
            />
          </ListItem>
          {index !== suggestions.length - 1 && <Divider />}
        </Fragment>
      ))}
    </List>
  );
};

export default SuggestionList;
