import NearMeIcon from "@mui/icons-material/NearMe";
import { IconButton } from "@mui/material";

const NavigationIcon = ({ latitude, longitude }) => {
  const openGoogleMaps = () => {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <IconButton
      size="small"
      onClick={openGoogleMaps}
      style={{
        backgroundColor: "#1a73e8",
        "&:hover, &:focus": {
          backgroundColor: "#1a73e8",
        },
        marginRight: "10px",
      }}
    >
      <NearMeIcon style={{ color: "#fff" }} fontSize="10" />
    </IconButton>
  );
};

export default NavigationIcon;
