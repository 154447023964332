import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BottomButton from "@/components/BottomButton";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import BasePriceInputAndPricePreview from "./components/BasePriceInputAndPricePreview";
import HelperAlert from "./components/HelperAlert";
import PricingMultipliersGroup from "./components/PricingMultipliersGroup";
import useFetchPricing from "./hooks/useFetchPricing";

const PricingPage = () => {
  const navigate = useNavigate();
  const {
    configuration,
    basePrice,
    setBasePrice,
    cuisineMultipliers,
    setCuisineMultipliers,
    mealTypeMultipliers,
    setMealTypeMultipliers,
    mealTimeMultipliers,
    setMealTimeMultipliers,
    periodMultipliers,
    setperiodMultipliers,
  } = useFetchPricing();

  const preparePricingData = () => {
    const createMultipliers = (multipliers, category) => {
      return multipliers.map((item) => ({
        [category]: item.state,
        multiplier: item.value,
      }));
    };
    return {
      basePrice: +basePrice,
      cuisineMultipliers: createMultipliers(cuisineMultipliers, "cuisine"),
      mealTypeMultipliers: createMultipliers(mealTypeMultipliers, "mealType"),
      mealTimeMultipliers: createMultipliers(mealTimeMultipliers, "mealTime"),
      periodMultipliers: createMultipliers(periodMultipliers, "period"),
    };
  };

  const submitHandler = async () => {
    try {
      if (!basePrice) {
        return toast.error("Base price is required");
      }
      const pricingData = preparePricingData();
      await api.kitchenRegistrations.updatePricing(pricingData);
      navigate(`/kitchen-registration/bank-details`);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Pricing</Title>
        <HelperAlert />
        <BasePriceInputAndPricePreview
          price={basePrice}
          setPrice={setBasePrice}
          configuration={configuration}
          pricing={preparePricingData()}
        />
        <PricingMultipliersGroup
          title={"Cuisine Multipliers"}
          multipliers={cuisineMultipliers}
          setMultipliers={setCuisineMultipliers}
        />
        <PricingMultipliersGroup
          title={"Meal Type Multipliers"}
          multipliers={mealTypeMultipliers}
          setMultipliers={setMealTypeMultipliers}
        />
        <PricingMultipliersGroup
          title={"Meal Time Multipliers"}
          multipliers={mealTimeMultipliers}
          setMultipliers={setMealTimeMultipliers}
        />
        <PricingMultipliersGroup
          title={"Period Multipliers"}
          multipliers={periodMultipliers}
          setMultipliers={setperiodMultipliers}
        />
        <BottomButton onClick={submitHandler}>Bank Details</BottomButton>
      </Container>
    </>
  );
};

export default PricingPage;
