import { Card, CardContent } from "@mui/material";

import Title from "@/components/Title";
import { verticalComponentMargin } from "@/utils/styleConstants";

import DeliveryCalendar from "./DeliveryCalendar";
import DeliveryProgress from "./DeliveryProgress";
import { useOrderPageContext } from "../../contexts/OrderPageContext";

const DeliveryManagement = () => {
  const { order } = useOrderPageContext();

  return (
    <>
      {order.finalStatus !== "pending" && !order.isPaymentExpired && (
        <>
          <Title alignLeft>Delivery</Title>
          <Card style={{ margin: verticalComponentMargin }}>
            <CardContent>
              <DeliveryProgress order={order} />
              <DeliveryCalendar order={order} />
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};

export default DeliveryManagement;
