import { Card, CardContent } from "@mui/material";

import LabelValueText from "@/components/LabelValueText";
import { verticalComponentMargin } from "@/utils/styleConstants";

import CardHeader from "./common/CardHeader";

const LegalDetailsDisplay = ({ isEditable, legalDetails }) => {
  let { fssai, gstin, pan } = legalDetails;

  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CardHeader
          title="Legal Details"
          editPath={isEditable && "/kitchen-registration/legal-details"}
        />
        <LabelValueText label="FSSAI" value={fssai} />
        <LabelValueText label="GSTIN" value={gstin} />
        <LabelValueText label="PAN" value={pan} />
      </CardContent>
    </Card>
  );
};

export default LegalDetailsDisplay;
