import { useEffect } from "react";
import { Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import BottomButton from "@/components/BottomButton";
import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

const initialValues = {
  pan: "",
  gstin: "",
  fssai: "",
};

const validationSchema = Yup.object({
  pan: Yup.string()
    .required("PAN is required")
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
      "Invalid PAN format (eg: AAAAA0000A)",
    ),
  gstin: Yup.string()
    .required("GSTIN is required")
    .matches(
      /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}\d{1}$/,
      "Invalid GSTIN format (eg: 01AAAA0000A000Z)",
    ),
  fssai: Yup.string()
    .required("FSSAI is required")
    .matches(/^\d{14}$/, "Invalid FSSAI format (eg: 1234567890123)"),
});

const LegalDetailsPage = () => {
  const navigate = useNavigate();

  const submitHandler = async (values) => {
    try {
      await api.kitchenRegistrations.updateLegalDetails(values);
      navigate(`/kitchen-registration/summary`);
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
  });

  useEffect(() => {
    const fetchLegalDetails = async () => {
      try {
        const {
          data: { kitchenRegistration },
        } = await api.kitchenRegistrations.getMyKitchenRegistration();
        const { legalDetails } = kitchenRegistration;

        if (legalDetails) {
          formik.setValues({
            pan: legalDetails.pan,
            gstin: legalDetails.gstin,
            fssai: legalDetails.fssai,
          });
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchLegalDetails();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title>Legal details</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "input",
                label: "PAN",
                state: "pan",
                inputType: "text",
              },
              {
                type: "input",
                label: "GSTIN",
                state: "gstin",
                inputType: "text",
              },
              {
                type: "input",
                label: "FSSAI",
                state: "fssai",
                inputType: "text",
              },
            ]}
          />
          <BottomButton>Summary</BottomButton>
        </form>
      </Container>
    </>
  );
};

export default LegalDetailsPage;
