import { useEffect, useState } from "react";
import { ArrowForward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import KitchenUserCard from "./components/KitchenUserCard";

const KitchenUsersPage = () => {
  const navigate = useNavigate();
  const [kitchenUsers, setKitchenUsers] = useState([]);

  const fetchKitchenUsers = async () => {
    try {
      const {
        data: { kitchenUsers },
      } = await api.kitchenUsers.getAll();
      setKitchenUsers(kitchenUsers);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchKitchenUsers();
  }, []);

  return (
    <>
      <Header />
      <Container>
        <Title
          button={
            <Button
              size="small"
              variant="contained"
              endIcon={<ArrowForward />}
              onClick={() => navigate("/kitchen-users/invites")}
            >
              Invites
            </Button>
          }
        >
          Kitchen users
        </Title>
        {kitchenUsers.map((kitchenUser) => (
          <KitchenUserCard
            key={kitchenUser._id}
            kitchenUser={kitchenUser}
            fetchKitchenUsers={fetchKitchenUsers}
          />
        ))}
      </Container>
    </>
  );
};

export default KitchenUsersPage;
