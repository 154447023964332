import Calendar from "react-calendar";
import { useNavigate, useParams } from "react-router-dom";

import "react-calendar/dist/Calendar.css";

import { deliveryStatusToColorMapping } from "@/utils/mappings";

import ColorLegend from "./ColorLegend";
import styles from "./DeliveryCalendar.module.css";

const DeliveryCalendar = ({ order }) => {
  const navigate = useNavigate();
  const { orderId } = useParams();

  const {
    startDate,
    endDate,
    deliverableDates = [],
    completedDates = [],
    skippedDates = [],
    missedDates = [],
    finalStatus,
  } = order;

  const handleDateClick = (date) => {
    const isoDate = date.toISOString();
    if (date >= new Date(startDate) && date <= new Date(endDate)) {
      navigate(`/orders/${orderId}/delivery/${isoDate}`);
    }
  };

  const legendItems = [
    {
      color: deliveryStatusToColorMapping.deliverable,
      description: "Deliverable",
    },
    {
      color: deliveryStatusToColorMapping.nonDeliverable,
      description: "Non Deliverable",
    },
    {
      color: deliveryStatusToColorMapping.completed,
      description: "Completed",
    },
    {
      color: deliveryStatusToColorMapping.skipped,
      description: "Skipped by user",
    },
    {
      color: deliveryStatusToColorMapping.missed,
      description: "Missed by kitchen",
    },
  ];

  if (order.finalStatus === "cancelled") {
    legendItems.push({
      color: deliveryStatusToColorMapping["cancelled"],
      description: "Cancelled",
    });
  }

  if (order.finalStatus === "expired") {
    legendItems.push({
      color: deliveryStatusToColorMapping["expired"],
      description: "Expired",
    });
  }

  return (
    <div style={{ margin: "15px 0px" }}>
      <Calendar
        minDate={new Date(startDate)}
        maxDate={new Date(endDate)}
        tileClassName={({ date }) => {
          const isoDate = date.toISOString();
          if (new Date(isoDate) <= new Date(endDate)) {
            if (completedDates.includes(isoDate)) {
              return styles["completed-date"];
            }
            if (skippedDates.includes(isoDate)) {
              return styles["skipped-date"];
            }
            if (missedDates.includes(isoDate)) {
              return styles["missed-date"];
            }
            if (
              finalStatus === "cancelled" &&
              order.remainingDates.includes(isoDate)
            ) {
              return styles["cancelled-date"];
            }
            if (
              finalStatus === "expired" &&
              order.remainingDates.includes(isoDate)
            ) {
              return styles["expired-date"];
            }
            if (deliverableDates.includes(isoDate)) {
              return styles["deliverable-date"];
            }
            return styles["non-deliverable-date"];
          }
          return null;
        }}
        onClickDay={handleDateClick}
      />
      <ColorLegend legendItems={legendItems} />
    </div>
  );
};

export default DeliveryCalendar;
