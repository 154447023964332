import DeliveryPage from "./pages/DeliveryPage";
import OrderPage from "./pages/OrderPage";
import OrdersPage from "./pages/OrdersPage";

const orderRoutes = [
  {
    path: "orders",
    component: <OrdersPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "orders/:orderId",
    component: <OrderPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
  {
    path: "orders/:orderId/delivery/:date",
    component: <DeliveryPage />,
    allowedKitchenRoles: ["owner", "admin"],
    isProtected: true,
  },
];

export default orderRoutes;
