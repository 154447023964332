import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import api from "@/api";
import { useData } from "@/contexts/DataContext";
import { isTimeInRange } from "@/utils/timeRangeUtils";

const DispatchTaskPageContext = createContext();

export const useDispatchTaskPageContext = () =>
  useContext(DispatchTaskPageContext);

export const DispatchTaskPageContextProvider = ({ children }) => {
  const { mealTimeDeliveryWindow } = useData();
  const { date, cuisine, mealType, mealTime } = useParams();

  const [deliveries, setDeliveries] = useState([]);

  const isDeliveryWindowOpen = isTimeInRange(mealTimeDeliveryWindow[mealTime]);

  const fetchDeliveries = useCallback(async () => {
    const {
      data: { deliveries },
    } = await api.dispatchTasks.getAllDeliveries({
      date,
      cuisine,
      mealType,
      mealTime,
    });
    setDeliveries(deliveries);
  }, [date, cuisine, mealType, mealTime]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  const contextValue = {
    deliveries,
    fetchDeliveries,
    isDeliveryWindowOpen,
  };

  return (
    <DispatchTaskPageContext.Provider value={contextValue}>
      {children}
    </DispatchTaskPageContext.Provider>
  );
};
