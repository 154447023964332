import AddMenuPage from "./pages/AddMenuPage";
import EditMenuPage from "./pages/EditMenuPage";
import MenusPage from "./pages/MenusPage";

const menuRoutes = [
  {
    path: "menus",
    component: <MenusPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menus/add",
    component: <AddMenuPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
  {
    path: "menus/:menuId/edit",
    component: <EditMenuPage />,
    allowedKitchenRoles: ["owner", "admin", "chef"],
    isProtected: true,
  },
];

export default menuRoutes;
