import { ArrowForward } from "@mui/icons-material";
import { Button, capitalize, Card, CardContent, Chip } from "@mui/material";

import CategoryTitle from "@/components/CategoryTitle";
import { verticalComponentMargin } from "@/utils/styleConstants";

const MenuScheduleCategoryCard = ({
  cuisine,
  mealType,
  mealTime,
  menuScheduleDays,
  clickHandler,
  btnName = "View menu schedules",
  btnColor = "primary",
}) => {
  return (
    <Card style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
        />
        <div style={{ display: "flex", flexWrap: "wrap", margin: "10px 0px" }}>
          {menuScheduleDays.map(({ day, menuSchedule }) => (
            <Chip
              key={day}
              label={capitalize(day)}
              size="small"
              style={{
                backgroundColor:
                  menuSchedule === null ? "lightcoral" : "lightgreen",
                margin: "3px",
                minWidth: "50px",
              }}
            />
          ))}
        </div>
        <Button
          fullWidth
          variant="contained"
          size="small"
          endIcon={<ArrowForward />}
          onClick={clickHandler}
          color={btnColor}
        >
          {btnName}
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuScheduleCategoryCard;
