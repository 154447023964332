import { Container } from "@mui/material";

import BottomDrawer from "@/components/BottomDrawer";

import OtpForm from "./OtpForm";

const OtpDrawer = ({ isOpen, setIsOpen, submitHandler, description }) => {
  return (
    <BottomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"OTP Verification"}
    >
      <Container style={{ paddingTop: "20px" }}>
        <OtpForm description={description} submitHandler={submitHandler} />
      </Container>
    </BottomDrawer>
  );
};

export default OtpDrawer;
