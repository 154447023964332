import React from "react";
import { Slider, Typography } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

const DeliveryRadiusSlider = ({ radius, setRadius }) => {
  const handleChange = (event, value) => {
    setRadius(value);
  };

  return (
    <div style={{ margin: verticalComponentMargin }}>
      <Typography variant="subtitle1" style={{ marginBottom: "10px" }}>
        Delivery Radius
      </Typography>
      <div style={{ width: "100%", padding: "20px 20px 0px" }}>
        <Slider
          value={radius}
          onChange={handleChange}
          min={1000}
          max={5000}
          step={100}
          valueLabelDisplay="on"
          aria-labelledby="radius-slider"
          valueLabelFormat={(value) => `${(value / 1000).toFixed(1)} km`}
        />
      </div>
    </div>
  );
};

export default DeliveryRadiusSlider;
