import { Button, capitalize, Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useData } from "@/contexts/DataContext";
import handleError from "@/utils/handleError";

const validationSchema = Yup.object({
  role: Yup.string().required("Role is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const SendInvitePage = () => {
  const navigate = useNavigate();
  const data = useData();
  const formik = useFormik({
    initialValues: {
      role: "",
      email: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await api.kitchenUserInvites.sendInvite({
          role: values.role,
          email: values.email,
        });
        toast.success("Invite sent successfully");
        navigate("/kitchen-users/invites");
      } catch (error) {
        handleError(error);
      }
    },
  });

  return (
    <>
      <Header />
      <Container>
        <Title>Send Invite</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "select",
                label: "Role",
                state: "role",
                value: "",
                options: data.allowedKitchenRolesForInvite.map((item) => ({
                  value: item,
                  label: capitalize(item),
                })),
              },
              {
                type: "input",
                label: "Email",
                state: "email",
                value: "",
              },
            ]}
          />
          <Button fullWidth variant="contained" type="submit">
            Send invite
          </Button>
        </form>
      </Container>
    </>
  );
};

export default SendInvitePage;
