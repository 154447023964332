import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { verticalComponentMargin } from "@/utils/styleConstants";

const NewRegistration = () => {
  const navigate = useNavigate();
  return (
    <>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Welcome to Mealsign Kitchen!
          </Typography>
          <Typography variant="body2">
            We're excited to have you as a kitchen partner in our mission to
            provide healthy, tasty, and affordable meals. Let's create delicious
            experiences together!
          </Typography>
        </CardContent>
      </Card>
      <Button
        onClick={() => navigate("/kitchen-registration/basic-details")}
        fullWidth
        variant="contained"
        color="success"
        endIcon={<ArrowForward />}
      >
        Start Your Registration
      </Button>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            Please have the following information ready to ensure a smooth
            registration process:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2">
                PAN (Permanent Account Number)
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                GSTIN (Goods and Services Tax Identification Number)
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                Bank Details (Account Number and IFSC Code)
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2">
                FSSAI (Food Safety and Standards Authority of India)
                Registration Number
              </Typography>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </>
  );
};

export default NewRegistration;
