import { Typography } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

const Title = ({ children, button, alignLeft, subtitle, margin }) => {
  let style = {
    margin: margin || verticalComponentMargin,
    textAlign: "center",
  };
  if (button) {
    style.display = "flex";
    style.justifyContent = "space-between";
    style.alignItems = "center";
    style.textAlign = "left";
  }
  if (alignLeft) {
    style.textAlign = "left";
  }

  let TitleWrapper = ({ children }) => <>{children}</>;

  if (button) {
    TitleWrapper = ({ children }) => <div>{children}</div>;
  }

  return (
    <div style={style}>
      <TitleWrapper>
        <Typography variant="h6">{children}</Typography>
        {subtitle && (
          <Typography variant="body2" color="text.secondary">
            {subtitle}
          </Typography>
        )}
      </TitleWrapper>
      {button}
    </div>
  );
};

export default Title;
