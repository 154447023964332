import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import MenuScheduleCategoryCard from "../components/MenuScheduleCategoryCard";

const PendingMenuScheduleCategoriesPage = () => {
  const navigate = useNavigate();
  const [pendingMenuScheduleCategories, setPendingMenuScheduleCategories] =
    useState([]);

  useEffect(() => {
    const fetchPendingMenuScheduleCategories = async () => {
      try {
        const {
          data: { pendingMenuScheduleCategories },
        } = await api.menuSchedules.getPendingMenuScheduleCategories();
        setPendingMenuScheduleCategories(pendingMenuScheduleCategories);
      } catch (error) {
        handleError(error);
      }
    };
    fetchPendingMenuScheduleCategories();
  }, []);

  if (pendingMenuScheduleCategories.length === 0) {
    return navigate("/kitchen/activate", { replace: true });
  }

  return (
    <>
      <Header />
      <Container>
        <Title>Pending Menu Schedules</Title>
        {pendingMenuScheduleCategories.map(
          ({ _id, cuisine, mealType, mealTime, menuScheduleDays }) => (
            <MenuScheduleCategoryCard
              key={_id}
              cuisine={cuisine}
              mealType={mealType}
              mealTime={mealTime}
              menuScheduleDays={menuScheduleDays}
              clickHandler={() =>
                navigate(
                  `/menu-schedules/pending/days/${cuisine}/${mealType}/${mealTime}`,
                )
              }
              btnName="Complete"
              btnColor="success"
            />
          ),
        )}
      </Container>
    </>
  );
};

export default PendingMenuScheduleCategoriesPage;
