import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card, CardContent, Chip, IconButton, Typography } from "@mui/material";
import { toast } from "react-toastify";

import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";
import { verticalComponentMargin } from "@/utils/styleConstants";

import DeleteDialog from "./DeleteDialog";

const KitchenUserCard = ({ kitchenUser, fetchKitchenUsers }) => {
  const { kitchenRole } = useKitchen();
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const deleteHandler = async () => {
    try {
      await api.kitchenUsers.delete({ kitchenUserId: kitchenUser._id });
      toast.success("Kitchen user deleted successfully");
      await fetchKitchenUsers();
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Card style={{ margin: verticalComponentMargin }}>
        <CardContent
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Chip size="small" label={kitchenUser.role} />
            <Typography variant="body2" style={{ marginTop: "10px" }}>
              {kitchenUser.user.email}
            </Typography>
          </div>
          {(["chef", "rider"].includes(kitchenUser.role) ||
            (kitchenRole === "owner" && kitchenUser.role === "admin")) && (
            <IconButton color="error" onClick={openDialog}>
              <DeleteIcon />
            </IconButton>
          )}
        </CardContent>
      </Card>
      <DeleteDialog
        open={open}
        setOpen={setOpen}
        deleteHandler={deleteHandler}
      />
    </>
  );
};

export default KitchenUserCard;
