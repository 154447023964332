import { Chip, Typography } from "@mui/material";

import { orderStatusToMuiColor } from "@/utils/mappings";
import { verticalComponentMargin } from "@/utils/styleConstants";

import { useOrderPageContext } from "../contexts/OrderPageContext";

const OrderHeader = () => {
  const { order } = useOrderPageContext();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: verticalComponentMargin,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6">Order</Typography>
        <Chip
          style={{ marginLeft: "10px" }}
          size="small"
          label={order.finalStatus}
          color={orderStatusToMuiColor[order.finalStatus]}
        />
      </div>
    </div>
  );
};

export default OrderHeader;
