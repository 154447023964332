import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useParams } from "react-router-dom";

import CategoryTitle from "@/components/CategoryTitle";
import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { useKitchen } from "@/contexts/KitchenContext";
import handleError from "@/utils/handleError";

import MenuScheduleDayCard from "./components/MenuScheduleDayCard";

const MenuScheduleDaysPage = () => {
  const { kitchen } = useKitchen();
  const { cuisine, mealType, mealTime } = useParams();
  const [menuScheduleDays, setMenuScheduleDays] = useState([]);

  useEffect(() => {
    const fetchMenuScheduleDays = async () => {
      try {
        const {
          data: { menuScheduleDays },
        } = await api.menuSchedules.getMenuScheduleDays({
          kitchenId: kitchen._id,
          cuisine,
          mealType,
          mealTime,
        });
        setMenuScheduleDays(menuScheduleDays);
      } catch (error) {
        handleError(error);
      }
    };
    fetchMenuScheduleDays();
  }, [cuisine, mealType, mealTime, kitchen._id]);

  return (
    <>
      <Header />
      <Container>
        <Title>Menu Schedules</Title>
        <CategoryTitle
          cuisine={cuisine}
          mealType={mealType}
          mealTime={mealTime}
          textAlign="center"
        />
        {menuScheduleDays.map(({ day, menuSchedule }) => (
          <MenuScheduleDayCard
            key={day}
            day={day}
            menuSchedule={menuSchedule}
          />
        ))}
      </Container>
    </>
  );
};

export default MenuScheduleDaysPage;
