import authRoutes from "@/modules/auth/authRoutes";
import deliveryTaskRoute from "@/modules/deliveryTasks/deliveryTaskRoute";
import dispatchTaskRoutes from "@/modules/dispatchTasks/dispatchTaskRoutes";
import homeRoute from "@/modules/home/homeRoute";
import kitchenRoutes from "@/modules/kitchen/kitchenRoutes";
import kitchenRegistrationRoutes from "@/modules/kitchenRegistration/kitchenRegistrationRoutes";
import kitchenUserRoutes from "@/modules/kitchenUsers/kitchenUserRoutes";
import menuRoutes from "@/modules/menus/menuRoutes";
import menuScheduleRoutes from "@/modules/menuSchedules/menuScheduleRoutes";
import orderRoutes from "@/modules/orders/orderRoutes";
import settlementRoute from "@/modules/settlements/settlementRoute";

const routes = [
  ...authRoutes,
  homeRoute,
  ...kitchenRegistrationRoutes,
  ...kitchenRoutes,
  ...menuScheduleRoutes,
  ...menuRoutes,
  ...kitchenUserRoutes,
  ...dispatchTaskRoutes,
  deliveryTaskRoute,
  ...orderRoutes,
  settlementRoute,
];

export default routes;
