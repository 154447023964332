import { useState } from "react";
import { Button, Container } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "@/components/FormComponents";
import Header from "@/components/Header";
import { OtpDrawer } from "@/components/OtpVerification";
import Title from "@/components/Title";
import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const LoginWithOtpPage = () => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const triggerOtp = async (values) => {
    try {
      await api.auth.triggerLoginOtp({
        email: values.email,
      });
      setIsDrawerOpen(true);
    } catch (error) {
      handleError(error);
    }
  };

  const verifyOtp = async (otp) => {
    try {
      const res = await api.auth.verifyLoginOtp({
        email: formik.values.email,
        otp,
      });
      authCtx.login({ token: res.token, user: res.data.user });
      toast.success("Logged in successfully.");
      navigate("/", { replace: true });
    } catch (error) {
      handleError(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: triggerOtp,
  });
  return (
    <>
      <Header hideRightSection />
      <Container>
        <Title>Login with OTP</Title>
        <form onSubmit={formik.handleSubmit}>
          <FormComponents
            formik={formik}
            data={[
              {
                type: "input",
                label: "Email",
                state: "email",
                value: "",
                inputType: "email",
              },
            ]}
          />
          <Button fullWidth variant="contained" type="submit">
            Login with OTP
          </Button>
        </form>
        <OtpDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          submitHandler={verifyOtp}
          description="Please enter the 6 digit code sent to your email address to login. valid for 10 minutes."
        />
      </Container>
    </>
  );
};

export default LoginWithOtpPage;
