import { useState } from "react";
import { Button } from "@mui/material";

import MissDeliveryDialog from "@/components/MissDeliveryDialog";
import api from "@/api";
import handleError from "@/utils/handleError";

const MissDeliveryButton = ({ deliveryId, fetchNearestDelivery }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const missDeliveryHandler = async ({ reason }) => {
    try {
      await api.deliveries.miss({
        deliveryId,
        reason,
      });
      await fetchNearestDelivery();
    } catch (error) {
      handleError(error);
    } finally {
      closeDialog();
    }
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="error"
        onClick={openDialog}
      >
        Miss
      </Button>
      <MissDeliveryDialog
        description={"Are you sure you want to miss this delivery?"}
        open={open}
        setOpen={setOpen}
        submitButtonName={"Miss"}
        submitHandler={missDeliveryHandler}
      />
    </>
  );
};

export default MissDeliveryButton;
