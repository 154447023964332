import { Typography } from "@mui/material";
import { CircularProgressbar } from "react-circular-progressbar";

import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ actual, total, label, color }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgressbar
          value={(actual / total) * 100}
          text={`${actual} / ${total}`}
          styles={{
            path: { stroke: color },
            text: { fill: color },
          }}
        />
      </div>
      <Typography
        style={{ color, marginTop: "5px", textAlign: "center" }}
        variant="caption"
      >
        {label}
      </Typography>
    </div>
  );
};

export default ProgressBar;
