import { TextField } from "@mui/material";

import getPropertyValue from "./getPropertyValue";

const OtpInput = ({
  formik,
  label,
  state,
  disabled = false,
  ...restOfProps
}) => {
  const { values, touched, errors, handleBlur, setFieldValue } = formik;

  const value = getPropertyValue(values, state) || "";
  const isTouched = getPropertyValue(touched, state);
  const error = getPropertyValue(errors, state);
  const showError = Boolean(isTouched && error);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > 6) return;
    const result = inputValue.replace(/\D/g, "");
    setFieldValue(state, result);
  };

  return (
    <TextField
      fullWidth
      variant="outlined"
      size="small"
      label={label}
      name={state}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={showError}
      helperText={showError ? error : " "}
      type="tel"
      disabled={disabled}
      inputProps={{ maxLength: 6 }}
      style={{ marginBottom: "8px" }}
      {...restOfProps}
    />
  );
};

export default OtpInput;
