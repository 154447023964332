import { Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import FormComponents from "@/components/FormComponents";
import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import handleError from "@/utils/handleError";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = ({ signupClickHandler }) => {
  const navigate = useNavigate();
  const authCtx = useAuth();

  const submitHandler = async (data) => {
    try {
      const res = await api.auth.login(data);
      authCtx.login({ token: res.token, user: res.data.user });
      toast.success("Logged in successfully.");
      navigate("/", { replace: true });
    } catch (err) {
      handleError(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitHandler,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormComponents
        formik={formik}
        data={[
          {
            type: "input",
            label: "Email",
            state: "email",
            value: "",
            inputType: "email",
          },
          {
            type: "input",
            label: "Password",
            state: "password",
            value: "",
            inputType: "password",
          },
        ]}
      />

      <Button fullWidth variant="contained" type="submit">
        Login
      </Button>
      <div style={{ marginTop: "25px" }}>
        <Typography variant="subtitle1">Forgot Password?</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{ color: "#FF0000", cursor: "pointer" }}
            onClick={() => navigate("/auth/login-with-otp")}
          >
            Login with OTP
          </Typography>
          <Typography variant="subtitle2" style={{ margin: "0px 10px" }}>
            Or
          </Typography>
          <Typography
            variant="subtitle2"
            onClick={() => navigate("/auth/reset-password")}
            style={{ color: "#FF0000", cursor: "pointer" }}
          >
            Reset Here
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle1" style={{ marginTop: "10px" }}>
            Don't have an account?
          </Typography>
          <Typography>
            <Typography
              variant="subtitle2"
              style={{ color: "#FF0000", cursor: "pointer", display: "inline" }}
              onClick={signupClickHandler}
            >
              Sign Up Here
            </Typography>
          </Typography>
        </div>
      </div>
    </form>
  );
};

export default Login;
